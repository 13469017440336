.section__bg {
  background-color: #000;
  /* color: #fff; */
}

/* .wrapper {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 9rem;
  align-items: center;
} */

.desc {
  color: black;
}

@media (max-width: 768px) {
  .section__bg {
    overflow: hidden;
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
  }

  .sponsors {
    display: flex;
    flex-direction: column;
  }
}
