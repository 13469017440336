.slick-slider {
  position: relative;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 0 20px;
}
.slider2 .slick-list {
  position: relative;
  display: block !important;
  /* overflow: hidden; */
  padding: 0px !important;
  width: 100%;
  @media only screen and (max-width: 900px) {
    padding: 0px !important;
  }
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}
.slick-slide .slideContainer {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slick-slide .image {
  object-fit: contain;
  border-radius: 2px;
  min-width: 130px !important;
  /* width: 80%; */
  @media only screen and (max-width: 900px) {
    min-width: 100% !important;
  }
}

.slick-slide:not(.slick-active) {
  position: relative;
  z-index: 997;
}
.slick-slide.slick-active:not(.slick-current) {
  position: relative;
  z-index: 998;
  transform: scale(1);
  transition: 0.25s;
  /* width: 150px; */
}

.slider2 .slick-slide.slick-active.slick-current {
  position: relative;
  z-index: 999;
  transform: scale(1);
  transition: 0.25s;
  margin: 0;
  width: 300px;
  @media only screen and (max-width: 900px) {
    transform: scale(1);
    margin: 0;
  }
}

.slider2 .slick-slide.slick-active.slick-current div span {
  font-size: 15px;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
}
.slider2 .slick-slide.slick-active.slick-current p {
  /* width: 400px; */
  font-size: 15px;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
}
.slick-slide:not(.slick-active) div {
  display: none;
  @media only screen and (max-width: 900px) {
    display: block;
  }
}
.slick-slide.slick-active:not(.slick-current) .image,
.slick-slide.slick-active.slick-current .image,
.slick-slide:not(.slick-active) .image {
  width: 100% !important;
  @media only screen and (max-width: 900px) {
    width: 100% !important;
  }
}
.slick-prev,
.slick-next {
  display: none !important;
}
