/*** 
====================================================================
  Table of contents
====================================================================

- Google Fonts
- Css Imports
- General Css Styles
- Header Area style
- Welcome Area Styles 
- Partners area style
- About us area style 
- services-block-four style 
- Demo-video area style 
- Trust area style 
- services area style 
- Contribution Cycle style
- Call-to-action area style
- Video area style 
- Gallery area style 
- Cool-facts style 
- Price table style
- Testimonials area style 
- Team area style
- Blog area style 
- Contact us style 
- FAQ style -Timeline style 
- Footer area style 

***/

/*** 

====================================================================
  Google fonts
====================================================================

***/

@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@700&display=swap");

/*** 

====================================================================
  Import All Css
====================================================================

***/

@import "bootstrap.min.css";
@import "animate.css";
/* @import 'owl.carousel.min.css'; */
@import "font-awesome.min.css";
/* @import 'font-icons.css'; */
@import "themify-icons.css";

/*** 

====================================================================
  General css style
====================================================================

***/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background: -webkit-linear-gradient(to right, #df0a1b, #aa0b22);
  background: -webkit-linear-gradient(left, #df0a1b, #aa0b22);
  background: linear-gradient(to right, #df0a1b, #aa0b22);
}
.darker-blue {
  background: linear-gradient(
    180deg,
    #240044 0,
    #0f0240 25%,
    #400959 40%,
    #0f0240 65%,
    #0f0240
  );
}
.darker {
  background: #000000;
}
.darker2 {
  background: #0f0f0f;
}
.darkest {
  background: #0f0f0f;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans", sans-serif;
  color: #fff;
  line-height: 1.4;
  font-weight: 500;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  outline: none;
}

li {
  list-style: none;
}

p {
  line-height: 1.9;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
.section-header {
  position: relative;
  margin-bottom: 50px;
}
.section-title {
  font-size: 44px;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  text-align: center;
  color: #fff;
}
.section-header .desc {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}
.bg-title {
  margin-bottom: -37px;
  margin-left: -30px;
  font-size: 66px;
  opacity: 0.04;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
}
.has-print {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  /* background: url(../img/core-img/shape1.png) no-repeat; */
}
.header-area .light.classy-nav-container a {
  color: #fff;
}
/*
.header-area.sticky .light.classy-nav-container a{
    color: #333
}
*/
.header-area.sticky .classy-navbar-toggler .navbarToggler span {
  background: #333 !important;
}
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  background: #df0a1b;
  background: -webkit-linear-gradient(to right, #df0a1b, #aa0b22);
  background: -webkit-linear-gradient(left, #df0a1b, #aa0b22);
  background: linear-gradient(to right, #df0a1b, #aa0b22);
}

.preload-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
  z-index: 100;
}

#dream-load {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2400ms linear infinite;
  animation: dreamrotate 2400ms linear infinite;
  z-index: 999;
}

#dream-load:before {
  content: "";
  position: absolute;
  top: 7.5px;
  left: 7.5px;
  right: 7.5px;
  bottom: 7.5px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 2700ms linear infinite;
  animation: dreamrotate 2700ms linear infinite;
}

#dream-load:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  border: 3px;
  border-style: solid;
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: dreamrotate 1800ms linear infinite;
  animation: dreamrotate 1800ms linear infinite;
}

@-webkit-keyframes dreamrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dreamrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Blip CSS */

.dream-blip {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 2em 2em 2em 2em;
  background-color: #21d397;
  box-shadow: 0 0 5px #fff;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-animation: dreamblipper ease 4s none infinite;
  animation: dreamblipper ease 4s none infinite;
}

@-webkit-keyframes dreamblipper {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  35% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes dreamblipper {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  35% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.dream-blip.blip1 {
  top: 20%;
  left: 20%;
}

.dream-blip.blip2 {
  top: 70%;
  left: 30%;
}

.dream-blip.blip3 {
  top: 30%;
  left: 10%;
}

.dream-blip.blip4 {
  top: 60%;
  left: 10%;
}

.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-100 {
  margin-right: 100px;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
@media (max-width: 767px) {
  .no-padding-right {
    padding-right: 15px !important;
  }
  .no-padding-left {
    padding-left: 15px !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-100 {
  margin-left: 100px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-60 {
  padding-top: 60px;
}
@media (max-width: 992px) {
  .double-bg {
    background-size: cover !important;
  }
  .mt-s {
    margin-top: 30px !important;
  }
  .padding-t-md-0 {
    padding-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .mt-x {
    margin-top: 30px !important;
  }

  .main-ilustration-5 {
    height: 65vh !important;
  }
}
@media (max-width: 480px) {
  .welcome_area.ico {
    height: 100vh !important;
  }
  .main-ilustration-5 {
    display: none;
  }
  .integration-text {
    font-size: 12px;
  }
  .integration-icon .badge {
    display: none;
  }
  .spons {
    border-top: 1px solid #eee;
  }
  .spons img {
    width: 80%;
  }
}
.section-padding-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section-padding-100-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}
.section-padding-0-70 {
  padding-top: 0px;
  padding-bottom: 70px;
}
.section-padding-0-40 {
  padding-top: 0px;
  padding-bottom: 40px;
}
.section-padding-100-40 {
  padding-top: 100px;
  padding-bottom: 40px;
}
.section-padding-100-85 {
  padding-top: 100px;
  padding-bottom: 85px;
}
.section-padding-0-100 {
  padding-top: 0px;
  padding-bottom: 100px;
}
.section-before:before {
  content: "";
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  width: 150px;
  /* background: url(../img/core-img/shape1.png) no-repeat; */
  background-size: contain;
}
.scew-bg {
  /* background: url(../img/svg/bg-header1.svg) no-repeat; */
  background-size: cover;
}
.travel-bg {
  /* background: url(../img//bg-img/travel-bg.png) no-repeat; */
  background-size: cover;
}
.relative {
  position: relative;
}
.box-shadow {
  -webkit-box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  box-shadow: 0px 10px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: block;
  top: 0;
}

.section-padding-0-100 {
  padding-top: 0;
  padding-bottom: 100px;
}

.section-padding-100-0 {
  padding-top: 100px;
  padding-bottom: 0;
}

.section-padding-100-50 {
  padding-top: 100px;
  padding-bottom: 50px;
}

.section-padding-0-50 {
  padding-top: 0;
  padding-bottom: 50px;
}

.section-padding-50-0 {
  padding-top: 50px;
  padding-bottom: 0;
}

.section-padding-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.section-padding-0-200 {
  padding-top: 0;
  padding-bottom: 200px;
}

.section-padding-200-0 {
  padding-top: 200px;
  padding-bottom: 0;
}

.section-padding-200-100 {
  padding-top: 200px;
  padding-bottom: 100px;
}

.section-padding-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section-padding-150-0 {
  padding-top: 150px;
  padding-bottom: 0;
}

.section-padding-0-150 {
  padding-top: 0;
  padding-bottom: 150px;
}

.section-padding-50-150 {
  padding-top: 50px;
  padding-bottom: 150px;
}

.section-heading {
  position: relative;
  z-index: 0;
  margin-bottom: 80px;
}

.section-heading h2 {
  font-size: 38px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.section-heading > p {
  margin-bottom: 0;
  line-height: 2;
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto;
}

#scrollUp {
  bottom: 130px;
  font-size: 12px;
  line-height: 22px;
  right: 30px;
  width: 100px;
  background-color: transparent;
  color: #fff;
  text-align: center;
  height: 20px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#scrollUp:before {
  position: absolute;
  width: 30%;
  height: 2px;
  background-color: #fff;
  content: "";
  top: 10px;
  right: 100%;
  z-index: -200 !important;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.has-gradient {
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
}
.has-border-top {
  border-top: 1px solid #eee;
}
@media (max-width: 992px) {
  .has-border-top-md {
    border-top: 1px solid #eee;
  }
}
.bg-overlay:after {
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.height-400 {
  height: 400px;
}

.height-600 {
  height: 600px;
}

.height-700 {
  height: 700px;
}

.height-800 {
  height: 800px;
}

.height-900 {
  height: 900px;
}

.height-1000 {
  height: 1000px;
}

.dream-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  z-index: 1 !important;
}

.dream-dots span {
  font-weight: bold;
  position: relative;
  display: inline-block;
  background: #aa0b22;
  color: #fff !important;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 25px;
  text-transform: uppercase;
}
.dream-dots span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  bottom: -6px;
  right: -6px;
  border-radius: 5px;
  border: 1px solid #eee;
  z-index: -1;
}

.login-btn {
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 12px !important;
  text-transform: uppercase;
  line-height: 33px;
  padding: 0 20px;
  min-width: 100px;
  color: #df0a1b !important;
  background-color: #ffffff;
  /* background: linear-gradient(to right, #a4bafe 0%, #7450fe 100%); */
  height: 35px;
  border-radius: 5px;
  letter-spacing: 1px;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff !important;
  background: #df0a1b;
  border-color: #df0a1b;
}

.dream-btn {
  position: relative;
  z-index: 1;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  background-image: -webkit-linear-gradient(
    to right,
    #aa0b22 0%,
    #f17674 51%,
    #aa0b22 100%
  );
  background-image: linear-gradient(
    to right,
    #aa0b22 0%,
    #f17674 51%,
    #aa0b22 100%
  );
  border: 1px solid #fff;
  -webkit-transition: all 500ms;
  transition: all 500ms;
}
/*
    background-image: -webkit-linear-gradient(to right,#FF4137 0,#FDC800 51%,#FF4137 100%);
    background-image: linear-gradient(to right,#FF4137 0,#FDC800 51%,#FF4137 100%);
    box-shadow: 0 0 15px 0 rgba(255, 125, 55, 0.9);
*/
.dream-btn:hover {
  background-position: right center;
  color: #fff;
}

.more-btn {
  position: relative;
  z-index: 0;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 3ox 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  /* background-image: linear-gradient(
    to right,
    #a4bafe 0%,
    #7450fe 50%,
    #a4bafe 100%
  ); */
  background-color: #aa0b22;
  transition: all 500ms;
}
.more-btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  bottom: -6px;
  right: -6px;
  border-radius: 5px;
  border: 1px solid #eee;
  z-index: -1;
}
.more-btn:hover {
  background-position: right center;
  color: #fff;
}
/* ### Clients #####*/
.client-logo-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 50.625rem;
  margin: 0 auto;
}
.client-logo {
  background: url(../img/svg/diamond-shape.svg) center no-repeat;
  background-size: cover;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 6.875rem;
  height: 8.125rem;
  margin: 0 -0.3rem;
}
.client-logo img {
  margin: -10px 6px 0 0;
  max-height: 2rem;
  max-width: 3rem;
}
@media (min-width: 1024px) {
  .client-logo img {
    max-width: 3.5rem;
    max-height: 3rem;
  }
}
/* ##### 2.0 Header Area CSS ##### */
.nav {
  transition: all 0.5s ease-in;
}
.navbar-brand {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
}
.lh-55px {
  line-height: 55px;
}
.navbar-nav .nav-item .nav-link {
  padding: 20px 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
}
.navbar-nav .nav-item .nav-link:hover {
  color: #fed136;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
  border-top: 3px solid #fed136;
}
.dropdown-item:hover {
  background-color: #42d897;
  color: #fff;
}
nav {
  -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
  -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
  transition: padding-top 0.3s, padding-bottom 0.3s;
  border: none;
}

.shrink {
  animation: 0.3s ease-in-out;
  animation-name: fadeInDown;
  background-color: rgb(215, 223, 239);
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.navbar-white .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-brand:hover {
  color: #333;
}

/* mobile view */
@media (max-width: 500px) {
  .navbar {
    background: rgb(215, 223, 239);
  }
  .navbar-nav {
    border-top: 1px solid #fed136;
    color: #fff;
    z-index: 1;
    margin-top: 5px;
  }
  .navbar-nav .nav-item .nav-link {
    padding: 0.7em 1em !important;
    font-size: 100%;
    font-weight: 500;
  }
}
.navbar-dark .navbar-toggler,
.navbar-white .navbar-toggler,
.navbar-cyan .navbar-toggler {
  background: #086bfe;
  border-color: #333;
}
.navbar-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' strokeWidth='2' strokeLinecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 992px) {
  .shrink {
    animation: none;
  }
  .login-btn {
    /* margin-left: 15px !important;
    margin-top: 15px !important; */
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    padding: 5px 12px;
    color: #333;
  }
  .navbar-expand-lg.navbar-dark {
    background: rgb(215, 223, 239);
  }
  .navbar-collapse {
    padding-top: 20px;
    background: rgb(215, 223, 239);
  }
}
.navbar-white.shrink .navbar-brand,
.shrink .navbar-nav .nav-item .nav-link {
  color: #333;
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #333;
}

.navbar-cyan .navbar-brand,
.navbar-cyan .navbar-nav .nav-item .nav-link {
  color: #04d0ff;
}

/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section {
  position: relative;
  min-height: 700px;
  /* background: url('../img/bg-img/header2.jpg') no-repeat bottom center; */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section.app {
  position: relative;
  min-height: 730px;
  /* background: url("../img/bg-img/header-app.png") no-repeat top center; */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section.de-3 {
  position: relative;
  min-height: 730px;
  background: url(../img/bg-img/bg-3.png) no-repeat top left;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-section.app img {
  width: 100%;
}
.hero-section.curved-section:after {
  content: "";
  position: absolute;
  width: 103%;
  height: 100%;
  left: 0px;
  right: 0px;
  bottom: -3px;
  background: url(../img/svg/curve.svg) no-repeat bottom;
  z-index: 0;
}
.hero-section.fullwidth-header {
  padding-top: 100px;
  padding-bottom: 365px;
  background-color: #446beb;
  /* background-image: url(../img/svg/fullwidth-header.svg); */
  background-position: 58% 100%;
  background-size: 1200px;
  background-repeat: no-repeat;
  text-align: center;
}
.header-il {
  position: relative;
  margin-top: 50px;
  padding: 10px;
  border: 2px solid #eee;
  border-radius: 200px 200px 0 0;
}
.hero-section .rounded-img {
  max-width: 100%;
  border-radius: 200px 200px 0 0;
}
.hero-section.fullwidth-header .special-head.dark {
  padding-left: 0;
}
.hero-section.fullwidth-header .special-head.dark:before {
  display: none;
}
@media (max-width: 991px) {
  .hero-section.fullwidth-header {
    padding-bottom: 285px;
    background-position: 50% 100%;
    background-size: 753px;
  }
}
@media (max-width: 991px) {
  .hero-section.fullwidth-header {
    background-position: 50% 100%;
    background-size: 897px;
  }
}
.hero-section.gradient {
  overflow: hidden;
  background-image: linear-gradient(106deg, #040044, #7222a7);
}
.hero-section.moving {
  overflow: hidden;
  background: url(../img/bg-img/wavy-bg.png) no-repeat center top;
}
.hero-section.moving-2 {
  overflow: hidden;
  /* background: url(../img/bg-img/moving-2.png) no-repeat center top;  */
  background-size: cover;
}
.simple-numbers {
  text-align: center;
}
.simple-numbers h3 {
  font-size: 40px;
  margin-bottom: 5px;
}
.moving-bg {
  position: relative;
  padding: 14px 0;
  border-top: 2px solid #1d4676;
  border-bottom: 2px solid #1d4676;
  /* visibility: inherit;
  -webkit-animation: cloudwash 18s linear infinite;
  -moz-animation: cloudwash 18s linear infinite;
  animation: cloudwash 18s linear infinite;
  display: inline-flex; */
}
.marquee-block {
  display: inline-block;
  width: 19em;
  background-color: #000;
  margin: 0 5px;
  place-content: center;
  padding: 1rem 0.5rem;
}
.marquee-block p {
  margin-bottom: 0;
  display: inline-block;
  padding-left: 10px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
/* .marquee-block img {
  max-width: 30px;
} */

@-webkit-keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes cloudwash {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@media (min-width: 767px) {
  .hero-section.curved-section {
    padding-top: 50px;
  }
}
@media (min-width: 992px) {
  .hero-section.curved-section {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .hero-section.curved-section img.curved {
    display: none;
  }
}
.hero-section.curved-section .special-head {
  padding-left: 0;
}
.hero-section.curved-section .special-head:before {
  display: none;
}
.welcome_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 900px !important;
}

.hero-content {
  width: 100%;
  height: 100%;
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
}
.hero-content.globe {
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.75)
  );
}
.hero-content.with-after {
  /* background: url('../img/svg/bg_hero.svg') no-repeat center right; */
}
.hero-content.with-after-before {
  /* background-image: url(../img/svg/bg_hero1.svg),url(../img/svg/bg_hero2.svg); */
  background-position: right top, left bottom;
  background-size: auto;
  background-repeat: no-repeat;
}
.hero-content.creative {
  /* background: url('../img/bg-img/header3.png') no-repeat center right; */
  background-size: cover;
}
.hero-content.gaming {
  /* background: url('../img/bg-img/game-bg.png') no-repeat center center; */
  background-size: cover;
}
.hero-content.pizza {
  /* background: url(../img/bg-img/banner-bg.jpg) no-repeat right top; */
}
.hero-section.fuel {
  /* background: url(../img/bg-img/banner-bg.png) no-repeat right bottom; */
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 365px;
}
.hero-content.tringle {
  /* background: url('../img/bg-img/tringle.png') no-repeat center right; */
}
.hero-content.scew {
  /* background: url('../img/bg-img/header2.png') no-repeat center top; */
}
.hero-content.trans {
  /* background: url('../img/bg-img/trans.png') no-repeat right top; */
}
.hero-content.transparent {
  background: transparent;
}
.hero-content.dark-blue {
  background: rgb(33, 0, 67, 0.9);
}
.hero-content .ico-counter {
  margin: 100px 7% 0;
}
@media (max-width: 992px) {
  .hero-content .ico-counter {
    margin-top: 0px !important;
  }
}
.hero-content .service-img-wrapper .image-box .rings {
  position: absolute;
  top: 50%;
  z-index: -1;
  margin: 0 auto;
  left: 50% !important;
  width: 120%;
  transform: translate(-50%, -50%);
  animation: unset;
  -webkit-animation: unset;
}
.welcome-content {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}
@media (max-width: 992px) {
  .gaming .welcome-content {
    margin-top: 120px;
  }
}

.promo-section {
  margin-bottom: 30px;
}
.special-head {
  color: #28cc8b;
  padding-left: 40px;
  font-size: 25px;
  position: relative;
}
.special-head:before {
  content: "";
  background: #3964f9;
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
}
.special-head.dark {
  color: #f8f517;
  font-weight: bold;
}
.special-head.dark:before {
  background: #fff;
}
.integration-link {
  padding: 8px 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  letter-spacing: 0.58px;
  font-size: 14px;
  color: #333;
  line-height: 24px;
  color: #d5d5ea;
}

.integration-link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
  border-radius: 100px;
}
.integration-icon {
  margin: 0 5px 0 0;
}
.integration-icon .badge {
  background: #14cab1;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 100px;
  padding: 6px 13px;
}
.integration-text {
  margin: 0 7px 0 0;
  color: #7e7b7b;
}
.integration-link > * {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
}

.main-ilustration {
  position: relative;
  /* background: url(../img/core-img/hero-bg.png) no-repeat center 65%; */
  height: 100vh;
  background-size: contain;
}
.main-ilustration-2 {
  position: relative;
  /* background: url(../img/core-img/robot-1.png) no-repeat center bottom; */
  height: 100vh;
  bottom: 0;
  background-size: contain;
}
.main-ilustration-3 {
  position: relative;
  /* background: url(../img/core-img/robot-2.png) no-repeat center bottom; */
  height: 100vh;
  bottom: 0;
  background-size: cover;
}
.main-ilustration-4 {
  position: relative;
  /* background: url(../img/svg/head-bg-1.svg) no-repeat center 65%; */
  height: 100vh;
  background-size: cover;
}
.main-ilustration-5 {
  position: relative;
  /* background: url(../img/core-img/about-5.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 100%;
}
.header-ilustration-1 {
  min-width: 0px;
  margin-top: 60px;
  position: relative;
}
.header-ilustration-1 img {
  width: 100%;
  border-radius: 10px;
}
.header-ilustration-1.small img {
  width: 85%;
}
.header-ilustration-1.big img {
  width: 105%;
}
.has-shadow {
  -webkit-box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.2);
  box-shadow: 0 9px 68px 0 rgba(62, 57, 107, 0.2);
  border-radius: 10px;
}
.header-ilustration-1 .video-btn-container {
  position: absolute;
  width: 150px;
  height: 150px;
  /* background: url(../img/core-img/video-btn-bg.png) no-repeat center center; */
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-ilustration-1 .video-btn-container .video-icon a {
  background: #fff;
  color: #2ea9fd;
}
.bub-right {
  /* background: url('../img/svg/bg_hero.svg') no-repeat center right; */
}
.bub-left {
  /* background: url('../img/svg/benefits-bg.svg') no-repeat center left; */
}

@media (max-width: 992px) {
  .header-ilustration-1 {
    margin-top: 0;
  }
  .bub-right {
    background-size: cover;
  }
  .bub-left {
    background-size: cover;
  }
}

.main-ilustration-6 {
  position: relative;
  /* background: url(../img/core-img/about-1.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 100%;
}
.main-ilustration-7 {
  position: relative;
  /* background: url(../img/core-img/faq.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 80%;
}
.main-ilustration-8 {
  position: relative;
  /* background: url(../img/bg-img/trading.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 100%;
  z-index: 2;
}
.dotted {
  position: relative;
}
.dotted .floating {
  width: 200px;
  height: 200px;
  right: -50px;
  top: 50px;
  position: absolute;
  background: url(../img/core-img/dots.png);
}
.main-ilustration-9 {
  position: relative;
  /* background: url(../img/core-img/travel.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 100%;
}
.main-ilustration-10 {
  position: relative;
  /* background: url(../img/core-img/pizza-header.png) no-repeat center 65%; */
  height: 100vh;
  background-size: 80%;
}

@media (max-width: 992px) {
  .main-ilustration-5 {
    height: 65vh;
  }
  .mt-md-30 {
    margin-top: 30px;
  }
  .mb-md-30 {
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .main-ilustration-3 {
    background-size: contain;
    background-position: left;
  }
  .main-ilustration-3 .inovation {
    right: 0% !important;
    top: 5%;
    display: inline-table;
  }
  .main-ilustration-3 .inovation.num2 {
    right: 5%;
    top: 27%;
  }
}

@media (max-width: 767px) {
  .mt-sm-30 {
    margin-top: 30px;
  }
  .mt-smy-0 {
    margin-top: 0px;
  }
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .main-ilustration-6 {
    height: 65vh;
  }
  .welcome-content {
    padding-right: 15px;
  }
  .main-ilustration-3 {
    background-size: contain;
    background-position: left;
  }
  .main-ilustration-3 .inovation {
    right: 0% !important;
    top: 42%;
    display: inline-table;
  }
  .main-ilustration-3 .inovation.num2 {
    right: 5%;
    top: auto;
  }
  .welcome-small-wrapper {
    position: absolute;
    width: 38%;
  }
  .welcome-content-small {
    position: absolute;
    top: -30px;
  }
  .welcome-content.ill {
    margin-top: 120px !important;
  }
  .header-ilustration-1 .video-btn-container {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 480px) {
  .main-ilustration-6,
  .header-ilustration-1 {
    display: none;
  }
  .welcome-content {
    vertical-align: middle;
    margin-top: 100px !important;
  }
  .main-ilustration-3 {
    display: none;
  }
  .welcome-small-wrapper {
    display: none;
  }
}
.main-ilustration-2:before {
  content: "";
  position: absolute;
  width: 30%;
  height: 1000px;
  left: 50%;
  top: -30%;
  transform: rotate(21deg);
  background: rgba(0, 0, 128, 0.7);
}
.main-ilustration-3 .inovation {
  position: absolute;
  bottom: 10%;
  right: -15%;
  width: 40%;
  background: #0d003b;
  padding: 20px;
}
.inovation.num2 {
  right: -57%;
}
.inovation h5 {
  padding-left: 25px;
  position: relative;
}
.inovation h5:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  width: 15px;
  height: 2px;
  background: #25cbd3;
}
.inovation p {
  font-size: 12px;
  margin-bottom: 0;
}
.ava {
  display: none;
}
.hero-section .bdge {
  position: absolute;
  left: 0px;
}
@media (min-width: 1200px) {
  .main-ilustration {
    min-width: 650px;
  }
  .hero-section .bdge {
    position: absolute;
    left: -60px;
  }
  .ava {
    display: block;
    position: absolute;
    left: 0;
    top: -105px;
    max-width: 120%;
  }
  .hero-section.gradient img {
    margin-bottom: -53px;
  }
}

.welcome-content h2 {
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 20px;
}
.welcome-content h1 {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.welcome-content h1.artficial {
  font-size: 38px;
}
.welcome-content p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
}

.character-features {
  position: relative;
  height: 100vh;
  border-left: 1px solid #00e0c4;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.header-video {
  position: absolute;
  width: 100%;
  height: 150px;
  bottom: 30px;
  left: 15px;
  /* background: url(../img/core-img/video-header-bg.png) no-repeat center center; */
}
@media (max-width: 992px) {
  .character-features,
  .header-video {
    display: none;
  }
}
.header-video span {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
}
.header-video .video-btn {
  width: 50px;
  height: 50px;
  color: #fff;
  border-radius: 50%;
  line-height: 50px;
  padding: 0;
  text-align: center;
  min-width: 50px;
  font-size: 18px;
}
.artficial {
  padding-left: 20px;
  border-left: 2px solid #fff;
  position: relative;
  margin-left: -30px;
}
.welcome-content-small {
  margin-bottom: 30px;
}
.welcome-content-small h4 {
  margin-bottom: 20px;
}
.artficial p {
  font-size: 12px;
}
.welcome-video-area {
  position: relative;
  z-index: 1;
  margin-top: 90px;
}

.welcome-video-area .welcome-thumb {
  position: relative;
  z-index: 1;
  margin-top: 30px;
  border: 1px solid #25cbd3;
  border-radius: 15px;
  padding: 0px 30px 0;
}

.welcome-video-area .welcome-thumb img {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  position: relative;
  top: -30px;
}

.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 40px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #df0a1b;
}

.video-btn {
  background-color: #df0a1b;
  width: 70px;
  height: 70px;
  color: #fff;
  border-radius: 50%;
  line-height: 70px;
  padding: 0;
  text-align: center;
  min-width: 70px;
  font-size: 24px;
}

.video-btn:hover {
  background-color: #df0a1b;
  color: #fff;
}

.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.welcome_area.video .desc {
  max-width: 60%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .fullscreen-bg {
    /* background: url('video/video-frame.PNG') center center / cover no-repeat; */
  }
  .welcome-content h1 {
    font-size: 35px;
  }
  .welcome_area.video .desc {
    max-width: 80%;
    margin: 0 auto;
  }
  .fullscreen-bg__video {
    display: none;
  }
}
.video-bg-illution {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #010111;
  z-index: 50;
  opacity: 0.7;
}

.marquee-info {
}
/* ##### Partners Area ##### */
.partners {
  padding: 0 0 70px;
}
.partner-box {
  border: 1px solid #25cbd3;
  border-radius: 10px;
  padding: 20px 10px;
  text-align: center;
  vertical-align: middle;
  background-color: #0d003b;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
.partner-box:hover {
  background: #25cbd3;
  cursor: pointer;
}
.b-text {
  color: #272e3a !important;
}
.w-text {
  color: #fff !important;
}
.g-text {
  color: #d8d0d0 !important;
}
.bold {
  font-weight: 600 !important;
}
.p-text {
  color: blueviolet !important;
}
.normal {
  font-weight: 500;
}
/* ##### About Us Area ##### */
.double-bg {
  /* background: url('../img/svg/section-bg.svg') no-repeat center left; */
  background-size: contain;
}
.double-bg-1 {
  position: relative;
  /* background: url('../img/bg-img/section-bg.png') no-repeat top right; */
  background-size: cover;
}
.about-us-area {
  position: relative;
  z-index: 1;
}
.about-bg {
  position: relative;
}
@media (min-width: 1200px) {
  .about-bg {
    /* background: url(../img/bg-img/about-bg.png) no-repeat right top; */
    background-size: contain;
    position: absolute;
    width: 71%;
    height: 100%;
    content: "";
    right: -18%;
    top: 0;
    z-index: -1;
  }
}
.supportImg {
  width: 700px;
  position: absolute;
  right: 0;
  top: 0%;
  height: 500px;
  z-index: -1;
  animation: fadeInAndOut 6s ease-in-out 0s infinite alternate;
}
@-webkit-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInAndOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.who-we-contant h4 {
  margin-bottom: 20px;
  font-size: 37px;
}

.collection {
  position: relative;
  background: url(../img/bg-img/header-1.jpg) no-repeat top center;
  background-size: cover;
}
.single_collection {
  padding: 25px;
  /* background: linear-gradient(
      180deg,
      rgba(158, 0, 255, 0.6) 0%,
      rgba(31, 30, 28, 0.6) 67.59%
    )
    rgba(255, 255, 255, 0.05); */
  background-color: white;
  margin-bottom: 30px;
}
.collection-img {
  max-width: 100%;
  overflow: hidden;
}
.collection-img img {
  transition: all ease-in-out 0.4s;
}
.single_collection:hover .collection-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.collection-info h5 {
  margin: 15px auto;
  font-size: 28px;
  width: 250px;
}

.our-mission-area {
  position: relative;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  height: 400px !important;
}

.our-mission-content {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
}

.single-mission {
  position: relative;
  z-index: 1;
  text-align: center;
}

.single-mission i {
  font-size: 50px;
  margin-bottom: 30px;
  display: block;
  color: #fff;
}

.single-mission h6 {
  margin-bottom: 15px;
}

.single-mission p {
  margin-top: 0;
  margin-bottom: 0;
}

.welcome-meter {
  position: relative;
  z-index: 1;
}
.welcome-meter.arficial {
  position: relative;
  z-index: 1;
  height: 427px;
  /* background: url(../img/svg/ai-illustration.svg) no-repeat; */
  background-size: 137%;
  background-position: center center;
}
@media (max-width: 992px) {
  .welcome-meter.arficial {
    background-size: 100%;
  }
}
.growing-company p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}

.growing-company p .counter {
  color: #fff;
}

.box-shadow:hover {
  -webkit-box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
  box-shadow: 0px 25px 36px 0px rgba(154, 161, 171, 0.24);
  top: -3px;
}
.article {
  background: #fff;
  padding: 25px 15px 15px;
  border: 1px solid #00dcd8;
  margin-bottom: 30px;
  text-align: left;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}
.article__icon {
  font-size: 40px;
  position: relative;
  color: #00dcd8;
}
.article__icon .flag-img {
  position: absolute;
  bottom: 19.8%;
  width: 30px;
  right: 12.7%;
}
.article__title {
  font-size: 18px;
  margin-top: 8px;
  color: #333;
}
.article p {
  color: #888;
}
.article:hover,
.article.hover {
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
}
.article:hover .article__title,
.article:hover p,
.article:hover .article__icon,
.article.hover .article__title,
.article.hover p,
.article.hover .article__icon {
  color: #fff;
}
/*** 

====================================================================
  Contribution Cycle style
====================================================================

***/

.vission .dark-row {
  padding: 30px;
}
.vission .dark-row p {
  margin-bottom: 0;
}
.dark-row {
  background: rgba(255, 255, 255, 0.05);
}
.cycle_icon {
  position: absolute;
  background: #262554;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.cycle_icon span {
  display: block;
  font-size: 23px;
  font-weight: 600;
}

.contribution-c-wapper {
  position: relative;
  padding: 30px;
}
.join-us .service_single_content {
  background: #030239;
  padding: 60px 15px 15px;
}

@media (min-width: 991px) {
  .has-arrow-right-lg:after,
  .has-arrow-left-lg:after {
    content: "";
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    right: -45px;
    width: 60px;
    height: 20px;
    /* background: url(../img/svg/join-right.svg) center right/100% auto no-repeat; */
  }
  .has-arrow-left-lg:after {
    transform: rotate(180deg);
  }
}
@media (max-width: 992px) {
  .has-arrow-bottom-md:after,
  .has-arrow-top-md:after {
    content: "";
    position: absolute;
    bottom: -13px;
    transform: translateX(-50%);
    right: 44%;
    width: 21px;
    height: 55px;
    /* background: url(../img/svg/join-bottom.svg) center  no-repeat; */
  }
  .has-arrow-top-md:after {
    transform: rotate(180deg);
    left: 47%;
  }
  .has-arrow-right-md:before,
  .has-arrow-left-md:before {
    content: "";
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    right: -45px;
    width: 60px;
    height: 20px;
    /* background: url(../img/svg/join-right.svg) center  no-repeat; */
  }
  .has-arrow-left-md:before {
    transform: rotate(180deg);
  }
}
@media (max-width: 767px) {
  .has-arrow-right-md:before,
  .has-arrow-left-md:before {
    display: none;
  }
  .has-arrow-down-sm:after {
    content: "";
    position: absolute;
    bottom: -13px;
    transform: translateX(-50%);
    left: 50%;
    width: 26px;
    height: 55px;
    /* background: url(../img/svg/join-bottom.svg) center  no-repeat; */
  }
}
.arrow-img {
  max-height: 60px;
}
.arrow-img.rotated {
  transform: rotate(180deg);
}

/*** 

====================================================================
  services-block-four style
====================================================================

***/
.features {
  position: relative;
}
.services-block-four {
  position: relative;
  margin-bottom: 30px;
  background: #fff;
}
.icon-img-box {
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 480px) {
  .icon-img-box {
    position: relative;
    margin-bottom: 15px;
  }
  .services-block-four .inner-box {
    padding-left: 0px !important;
  }
}
.services-block-four .inner-box {
  position: relative;
  padding-left: 100px;
}
.services-block-four.v2 .inner-box {
  position: relative;
  padding-left: 70px;
}

.services-block-four.v3 {
  position: relative;
  background: transparent;
  padding: 15px 0 5px;
}
.services-block-four.v3 .inner-box {
  padding-left: 85px;
}
.services-block-four.v3 .icon-img-box {
  top: 50%;
  transform: translateY(-50%);
}
.services-block-four.v3:after {
  content: "";
  position: absolute;
  width: 95%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 15px;
  z-index: -1;
  background: rgba(255, 255, 255, 0.05);
}

.bg-ring {
  /* background: url(../img/bg-img/bread-bg.png) no-repeat center center; */
  background-size: cover;
}

.features .services-block-four {
  padding: 30px 20px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.05);
}
.features .services-block-four .icon-img-box {
  max-width: 80px;
}
.features2 .services-block-four {
  padding: 20px 15px 10px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.special .services-block-four {
  padding: 20px 20px 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #181734;
}
.special .services-block-four .inner-box {
  position: relative;
  padding-left: 160px;
}

.demo-video.features .services-block-four {
  padding: 25px 20px 15px;
  margin-bottom: 20px;
}
.licenes .services-block-four .inner-box .icon-box {
  font-size: 56px;
  border-radius: 0%;
}
.licenes .services-block-four .inner-box .icon-box:after {
  border-radius: 0%;
}
.services-block-four .inner-box .icon-box:after,
.services-block-four .inner-box .icon-box:before {
  background: #25cbd3;
}
.services-block-four .inner-box .icon-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  border: 2px solid;
  font-size: 40px;
  line-height: 98px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px !important;
  transition: 0.3s ease;
}
.service-img-wrapper .image-box {
  position: relative;
}
@media (max-width: 1200px) {
  .service-img-wrapper .image-box .rings {
    left: 3% !important;
  }
  .service-img-wrapper .image-box {
    margin-top: 0 !important;
  }
}
@media (min-width: 992px) {
  .special-size {
    max-width: 120%;
  }
}
@media (max-width: 992px) {
  .service-img-wrapper .image-box {
    margin-top: 30px !important;
  }
  .service-img-wrapper .image-box.no-mt {
    margin-top: 0px !important;
  }
  .service-img-wrapper .phone-img {
    padding: 0 10% !important;
  }
  .service-img-wrapper .image-box .rings {
    left: 9% !important;
    width: 80%;
    z-index: -1;
  }
}

.service-img-wrapper .image-box .rings {
  position: absolute;
  top: -17%;
  z-index: -1;
  margin: 0 auto;
  left: 0%;
  transform: translate(-50%, -50%);
}
.services-block-four .inner-box .icon-box span {
  position: relative;
  z-index: 99;
}

.services-block-four .inner-box:hover .icon-box,
.services-block-four .inner-box:hover .icon-box span {
  color: #fff;
  transition: 0.5s ease;
}

.services-block-four .inner-box .icon-box:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.7s ease;
}
.services-block-four .inner-box:hover .icon-box:after {
  transform: scale(1);
  transition: 0.7s ease;
}

.services-block-four .inner-box .icon-box:before {
  position: absolute;
  content: "";
  left: 50%;
  top: 100%;
  width: 1px;
  height: 95%;
  background: #25cbd3;
}

.services-block-four:last-child .inner-box .icon-box:before {
  display: none;
}

.services-block-four .inner-box h3 {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.services-block-four.how .inner-box {
  padding-left: 70px;
}

.services-block-four.how {
  padding: 30px;
  margin-bottom: 30px;
  background: #fff;
}

@media (max-width: 992px) {
  .service-img-wrapper.how .image-box img {
    width: 100%;
    margin-bottom: 50px;
  }
}

.services-block-four .inner-box .step {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40px;
  height: 40px;
  border: 2px solid;
  background: #7d60f9;
  border-color: #7d60f9;
  font-size: 18px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 20px !important;
  transition: 0.3s ease;
}
.services-block-four.how .inner-box .text {
  margin-bottom: 0;
}

.services-block-four .inner-box h3 a {
  color: #fff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.services-block-four .inner-box .icon-box {
  color: #25cbd3;
}

.services-block-four .inner-box h3 a:hover {
}

.services-block-four .inner-box .text {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
  margin-bottom: 10px;
}

.services-block-four .inner-box .read-more {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services-block-four .inner-box .read-more:hover {
  color: #253267;
}
.service-img-wrapper .image-box img {
  animation: floating2 7s infinite;
  -webkit-animation: floating2 7s infinite;
}
.floating-anim {
  animation: floating2 7s infinite;
  -webkit-animation: floating2 7s infinite;
}
@media (min-width: 992px) {
  .service-img-wrapper .image-box {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .service-img-wrapper .image-box img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .services-block-four {
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .services-block-four .inner-box .step {
    position: relative;
  }
}
.service-img-wrapper .image-box {
  position: relative;
}
@keyframes floating2 {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
    -moz-transform: rotateX(0deg) translateY(15px);
    -ms-transform: rotateX(0deg) translateY(15px);
    -o-transform: rotateX(0deg) translateY(15px);
    transform: rotateX(0deg) translateY(15px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes floating2 {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    -webkit-transform: rotateX(0deg) translateY(15px);
    -moz-transform: rotateX(0deg) translateY(15px);
    -ms-transform: rotateX(0deg) translateY(15px);
    -o-transform: rotateX(0deg) translateY(15px);
    transform: rotateX(0deg) translateY(15px);
  }
  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

.features-list {
  background: rgba(255, 255, 255, 0.05);
  background-size: cover;
  padding: 30px;
  border-radius: 20px;
}
.features-list.v2 {
  background-image: linear-gradient(35deg, #17ead9 0%, #6078ea 100%);
  background-image: -webkit-linear-gradient(35deg, #17ead9 0%, #6078ea 100%);
}
.list-marked li {
  top: 8px;
  padding: 7px 0;
  color: #fff;
  left: 0;
}
.list-marked i {
  font-weight: normal;
  margin-right: 10px;
  color: #9a68ed;
  line-height: 30px;
  text-align: center;
  background: rgba(255, 255, 255, 0.09);
  width: 30px;
  height: 30px;
}
.features-list.v2 .list-marked i {
  background: #1c3368;
  color: #fff;
}
.counter-boxed-warrper {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-border-radius: 0 20px 20px 0;
  -moz-border-radius: 0 20px 20px 0;
  border-radius: 0 20px 20px 0;
  padding-bottom: 30px;
}
.counter-boxed-warrper:before {
  content: "";
  width: 100%;
  height: 64%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f1f6fc;
  z-index: -1;
  border-radius: 0 0px 274px 0px;
}
.counter-boxed {
  padding: 30px 20px 0;
}
.counter-boxed .counter {
  font-size: 36px;
}
.box-list .text-bismark {
  color: #96aabf;
  font-size: 16px;
}
@media (max-width: 767px) {
}

/* ##### demo-video Area CSS ##### */
.demo-video {
  position: relative;
}
.demo-video .welcome-video-area {
  margin-top: 0;
}
@media (max-width: 992px) {
  .vertical-social {
    display: none !important;
  }
}

.vertical-social {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: #0d003b;
  height: 370px !important;
  margin-left: 30px;
  border-radius: 40px;
  padding: 30px 15px;
  z-index: 999;
  transition: 1s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 4px 13px 0 rgba(168, 67, 253, 0.3);
  overflow: hidden;
  border-bottom: 3px solid #a843fd;
}
.vertical-social li {
  padding: 7px 0;
  text-align: center;
}
.vertical-social li a {
  color: #fff;
  opacity: 0.6;
  font-size: 22px;
}
.vertical-social li a:hover {
  opacity: 1;
}
/* ##### trust Area CSS ##### */
.trust-section {
  position: relative;
}
.trust-item {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 0 35px;
  min-height: 205px;
  padding-top: 37px;
  padding-bottom: 25px;
  box-shadow: 4px 4px 10px 0 rgba(168, 67, 253, 0.3);
  overflow: hidden;
  border-bottom: 3px solid #a843fd;
}
.ico-platform-logo {
  margin-bottom: 25px;
  min-height: 75px;
}
@media (max-width: 767px) {
  .ico-platform-logo {
    min-height: 107px;
  }
}
.check {
  height: 40px;
  margin: 0 -10px;
  background-color: rgba(13, 0, 59, 0.9);
  border-radius: 5px;
  color: #25cbd3;
  position: relative;
}
.check .value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(0, 243, 255, 0.5);
}
.check .check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../img/svg/checkmark.svg) 50% no-repeat;
  background-size: contain;
  width: 31px;
  height: 23px;
}
.gradient-section-bg {
  background-image: linear-gradient(106deg, #6c7cf5, #1a2dab);
  background-image: -webkit-linear-gradient(106deg, #6c7cf5, #1a2dab);
}
.dark-sec {
  background: #181734;
}
.token-distribution {
  padding-bottom: 100px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .token-info-wapper {
    margin-top: 30px;
  }
}
.token-information {
  position: relative;
}
.token-information span {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  border-radius: 50%;
}
.token-information li {
  padding: 5px 0;
  padding-left: 50px;
  position: relative;
}
.token-information h6 {
  color: #fff;
}
.token-information .one {
  border: 7px solid #997dea;
}
.token-information .two {
  border: 7px solid #e66392;
}
.token-information .three {
  border: 7px solid #2acd72;
}
.token-information .four {
  border: 7px solid #1eb8e0;
}
.token-information .five {
  border: 7px solid #b843b6;
}
.token-information .six {
  border: 7px solid #f5a67e;
}
.token-information .seven {
  border: 7px solid #e16e3a;
}
.token-information .eight {
  border: 7px solid #4581da;
}
.token-info {
  width: 100%;
  margin-bottom: 10px;
  float: left;
  display: -ms-flexbox;
  display: flex;
}
.token-info .info-wrapper {
  border-radius: 0 10px 10px 0;
  padding: 10px 15px;
  padding-left: 60px;
  background: rgba(255, 255, 255, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.25);
  width: 100%;
  position: relative;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
}
.token-info .info-wrapper.one {
  border-left: 7px solid #997dea;
}
.token-info .info-wrapper.two {
  border-left: 7px solid #e66392;
}
.token-info .info-wrapper.three {
  border-left: 7px solid #2acd72;
}
.token-info .info-wrapper.four {
  border-left: 7px solid #1eb8e0;
}
.token-info .info-wrapper.five {
  border-left: 7px solid #b843b6;
}
.token-info .info-wrapper.six {
  border-left: 7px solid #f5a67e;
}
.token-info .info-wrapper.seven {
  border-left: 7px solid #e16e3a;
}
.token-info .info-wrapper.eight {
  border-left: 7px solid #4581da;
}

.info-wrapper .token-icon {
  left: 12px;
  width: 38px;
  height: 38px;
  font-size: 25px;
  line-height: 38px;
  font-weight: 700;
  opacity: 1;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-position: 50%;
  background-size: contain;
}
.info-wrapper .token-icon.img {
  opacity: 1;
}
.info-wrapper .token-descr {
  display: block;
  font-size: 14px;
  color: #fff;
  padding-left: 0px;
  font-weight: 300;
  line-height: 1.25;
}
/* ##### Service Area CSS ##### */

.service_single_content {
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-in-out;
}
.service_single_content p {
  margin-bottom: 0;
}
.light-version .service_single_content.food {
  background: #5a20c6;
  padding-bottom: 40px;
  overflow: hidden;
  position: relative;
}
.light-version .service_single_content.food h6 {
  color: #fff;
}
.light-version .service_single_content.food h5 {
  position: absolute;
  top: 17px;
  right: -49px;
  transform: rotate(41deg);
  background: red;
  padding: 10px 58px;
  color: #fff;
}

.darky .service_single_content {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  background-size: cover;
  border: none;
  padding: 40px 20px;
  margin-bottom: 30px !important;
}
.darky.how .service_single_content:hover .service_icon {
  background: transparent;
}
.service_single_content.v2 {
  position: relative;
  padding: 30px 20px;
  /* background: linear-gradient(
    180deg,
    rgba(31, 30, 28, 0.6) 0%,
    rgba(158, 0, 255, 0.6) 67.59%
  ); */
  border-radius: 20px;
  margin-bottom: 60px;
}
.pizza-prop li {
  padding: 5px 0;
}
.pizza-prop p {
  display: inline-block;
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
  width: 50%;
  color: #cfc9c9 !important;
}
.pizza-prop span {
  width: 50%;
  color: #fff;
}
.service_single_content .service_icon i {
  font-size: 30px;
  margin-bottom: 20px;
  color: #fff;
  display: block;
}
.embed-video {
  max-width: 250px !important;
  height: auto !important;
}
.light-version .how .service_single_content {
  background: #fff;
}
.how .service_icon {
  padding: 30px;
  position: relative;
  border: 2px solid #00dcd8;
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
  max-width: 140px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
  .how {
    border-top: 1px solid #eee;
  }
}
.how .service_icon .white-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how .service_single_content:hover .service_icon {
  background: #fff;
}
.how .service_single_content:hover .white-icon {
  visibility: hidden;
}
.features .service_single_content {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px 15px;
}
.service_icon .step-num {
  position: absolute;
  top: 0px;
  right: -10px;
  background: #21d397;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
}
.service_icon {
  margin-bottom: 30px;
  margin-left: 40px;
  display: inline-block;
  max-width: 150px;
  min-height: 60px;
}
.service_single_content h6 {
  margin-bottom: 15px;
  font-size: 20px;
}
.step-num {
  position: absolute;
  top: -35px;
  /* right: 15px; */
  background: rgba(244, 246, 254, 0.8);
  padding: 7px 25px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  border-radius: 23px;
}

@media (max-width: 768px) {
  .step-num {
    right: 2%;
  }
}
.side-feature-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}
.check-mark-icon {
  margin-right: 16px;
  width: 30px;
  height: 30px;
}
.foot-c-info {
  font-weight: 500;
  color: #fff;
}
/* ##### 6.0 CTA Area CSS ##### */

.call_to_action_area {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 500px !important;
}

.cta-content h2 {
  font-size: 40px;
  margin-bottom: 15px;
}

.cta-content p {
  font-size: 18px;
  margin-bottom: 25px;
}

.cta-content-area {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(72, 52, 212, 0.95);
  background: -webkit-linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
  background: linear-gradient(
    to right,
    rgba(72, 52, 212, 0.95),
    rgba(52, 31, 151, 0.95)
  );
}
/*
* ----------------------------------------------------------------------------------------
*  START Roadmap 2 STYLE
* ----------------------------------------------------------------------------------------
*/
.section_5-content {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
}

.section_5-slider-trumb {
  display: inline-block;
  width: 100%;
  left: 0;
  height: 4px;
  background-color: #aa0b22;
  position: relative;
  border-radius: 3px;
}

#section_5-slider-circle {
  position: relative;
}

#section_5-slider-circle .data_cir {
  top: 0;
  position: absolute;
  margin-left: -8px;
  margin-top: -16px;
  width: 16px;
  height: 16px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #aa0b22;
  border: 4px solid #000;
  -webkit-box-shadow: 0 0 6px 2px #aa0b22;
  -moz-box-shadow: 0 0 6px 2px #aa0b22;
  box-shadow: 0 0 6px 2px #aa0b22;
}

#section_5-slider-circle .data_cir-content {
  position: relative;
  width: 200px;
  height: auto;
  display: inline-block;
  margin-left: -60px;
  text-align: center;
}

#section_5-slider-circle .data_cir-content p {
  font-size: 13px;
  color: #aa0b22;
  line-height: 2;
}

#section_5-slider-circle .data_cir-content > div {
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
}

#section_5-slider-circle .data_cir-content div {
  font-size: 13px;
  color: #777;
  letter-spacing: 0;
  width: 100%;
  line-height: 20px;
}
@media (max-width: 1200px) {
  #section_5-slider-circle .data_cir-content div {
    width: 94%;
    font-size: 11px;
  }
}
#section_5-slider-circle .data_cir:nth-child(2n + 1) .data_cir-content {
  bottom: 44px;
  -webkit-transform: translateY(-100%) translateX(-18%);
  -ms-transform: translateY(-100%) translateX(-18%);
  -moz-transform: translateY(-100%) translateX(-18%);
  -o-transform: translateY(-100%) translateX(-18%);
  transform: translateY(-100%) translateX(-18%);
}

#section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content {
  top: 55px;
  -webkit-transform: translateX(-18%);
  -ms-transform: translateX(-18%);
  -moz-transform: translateX(-18%);
  -o-transform: translateX(-18%);
  transform: translateX(-18%);
}

#section_5-slider-circle
  .data_cir:nth-child(2n)
  .data_cir-content
  .roadmap_img {
  -ms-order: -1;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  -webkit-order: -1;
  -moz-box-ordinal-group: 0;
  order: -1;
}

#section_5-slider-circle .data_cir:nth-child(2n) .roadmap_content_info {
  margin-top: 20px;
}

#section_5-slider-circle .data_cir:nth-child(2n + 1) .roadmap_content_info {
  margin-bottom: 20px;
}

#section_5-slider-circle .data_cir .data_cir-content ul {
  text-align: left;
  position: relative;
}

#section_5-slider-circle .data_cir .data_cir-content ul::before {
  content: "";
  position: absolute;
  border-left: 1px solid #aa0b22;
  left: -14px;
  opacity: 0.2;
  width: 45%;
  height: -webkit-calc(100% + 37px);
  height: -moz-calc(100% + 37px);
  height: calc(100% + 37px);
}

#section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content ul::before {
  border-top: 1px solid #aa0b22;
  margin-top: -46px;
}

#section_5-slider-circle
  .data_cir:nth-child(2n + 1)
  .data_cir-content
  ul::before {
  border-bottom: 1px solid #aa0b22;
  margin-top: 10px;
}

#section_5-slider-circle .data_cir .data_cir-content ul li {
  padding-left: 5px;
  list-style: disc;
  color: #aa0b22;
}

#section_5-slider-circle .data_cir .data_cir-content ul li span {
  color: #777;
}
.roadmap .section-heading > p {
  padding-left: 15px;
  padding-right: 15px;
}
#section_5-slider-circle .data_cir .data_cir-content .roadmap_img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #aa0b22;
  -webkit-box-shadow: 0 0 6px 1px rgba(0, 228, 255, 0.5);
  -moz-box-shadow: 0 0 6px 1px rgba(0, 228, 255, 0.5);
  box-shadow: 0 0 6px 1px rgba(0, 228, 255, 0.5);
}

#section_5-slider-circle .data_cir::before {
  content: "";
  opacity: 0.6;
  position: absolute;
  display: block;
  width: 1px;
  height: 40px;
  background-color: #aa0b22;
  left: -webkit-calc(50% - 1px);
  left: -moz-calc(50% - 1px);
  left: calc(50% - 1px);
}

#section_5-slider-circle .data_cir:nth-child(2n + 1)::before {
  top: -44px;
}

#section_5-slider-circle .data_cir:nth-child(2n)::before {
  top: 17px;
}

#section_5-slider-circle .data_cir::after {
  content: attr(data-date);
  position: absolute;
  display: block;
  color: #222;
  color: #777;
  width: 50px;
  color: #fff;
  font-size: 12px;
  left: 0;
  text-align: center;
  -webkit-transform: translateX(-35%);
  -ms-transform: translateX(-35%);
  -moz-transform: translateX(-35%);
  -o-transform: translateX(-35%);
  transform: translateX(-35%);
}

.form-block-rcl > .login-message {
  color: #fff;
}

#section_5-slider-circle .data_cir:nth-child(2n + 1)::after {
  top: 30px;
}

#section_5-slider-circle .data_cir:nth-child(2n)::after {
  top: -45px;
}

#section_5-slider-circle .data_cir.next {
  background-color: #000;
  border: 4px solid #000;
  -webkit-box-shadow: 0 0 6px 2px #aa0b22;
  -moz-box-shadow: 0 0 6px 2px #aa0b22;
  box-shadow: 0 0 6px 2px #aa0b22;
}
@media (max-width: 992px) {
  .section_5-slider-trumb {
    width: 4px;
    height: -webkit-calc(100% - 4px);
    height: -moz-calc(100% - 4px);
    height: calc(100% - 4px);
    top: 4px;
    position: absolute;
    left: -webkit-calc(45vw + 8px);
    left: -moz-calc(45vw + 8px);
    left: calc(45vw + 8px);
  }
  .section_5-content {
    position: relative;
  }
  .section_5-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  #section_5-slider-trumb {
    width: 4px;
    height: -webkit-calc(100% - 4px);
    height: -moz-calc(100% - 4px);
    height: calc(100% - 4px);
    top: 4px;
    position: absolute;
    left: -webkit-calc(45vw + 8px);
    left: -moz-calc(45vw + 8px);
    left: calc(45vw + 8px);
  }
  #section_5-slider-circle .data_cir {
    margin-top: 0px;
    margin-bottom: 150px;
    position: relative;
    left: 0 !important;
    margin-left: -webkit-calc(45vw + 2px);
    margin-left: -moz-calc(45vw + 2px);
    margin-left: calc(45vw + 2px);
  }
  #section_5-slider-circle .data_cir:last-child {
    margin-bottom: 0 !important;
  }
  #section_5-slider-circle .data_cir::before {
    top: 3px !important;
    height: 1px;
    width: 40px;
    left: auto;
    right: 14px;
  }
  #section_5-slider-circle .data_cir-content {
    margin-left: 60px;
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    transform: translateY(0) !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 250px;
  }
  #section_5-slider-circle .data_cir-content div {
    width: 100%;
    font-size: 14px;
  }
  #section_5-slider-circle .data_cir:nth-child(2n) .data_cir-content ul::before,
  #section_5-slider-circle
    .data_cir:nth-child(2n + 1)
    .data_cir-content
    ul::before {
    display: none;
  }
  #section_5-slider-circle .data_cir:first-child .data_cir-content > div {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
    text-align: left;
  }
  #section_5-slider-circle .data_cir-content > div {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    height: auto;
  }
  .roadmap_img {
    display: none !important;
  }
  #section_5-slider-circle .data_cir::after {
    left: auto;
    right: 55px;
    color: #222;
    padding: 5px 7px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    top: 4px !important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid #aa0b22;
  }
  #section_5-slider-circle .data_cir::after {
    width: 70px;
  }
}

/*
* ----------------------------------------------------------------------------------------
*  START Roadmap STYLE
* ----------------------------------------------------------------------------------------
*/
.timeline-split {
  position: relative;
  width: 100%;
}
.timeline-split .timeline {
  position: relative;
  padding: 50px;
  overflow: hidden;
}
.timeline-split .timeline h3 {
  font-size: 22px;
  color: #25cbd3;
}
.timeline-split .timeline span {
  color: #666;
  display: block;
  margin-bottom: 10px;
}
/*top circle */
.timeline-split .timeline::before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  background: #25cbd3;
  left: calc(50% - 10px);
}
/*vertival line */
.timeline-split .timeline::after {
  content: "";
  width: 2px;
  height: 4000px;
  display: block;
  position: absolute;
  top: 10px;
  left: 50%;
  left: calc(50% - 1px);
  background: #25cbd3;
}

/*timeline block */
.timeline-split .timeline .block {
  width: 50%;
  padding: 30px;
  background: #0d003b;
  border: 1px solid #25cbd3;
  width: calc(50% - 74px);
  text-align: left;
  position: relative;
}
.timeline .block .date {
  padding: 5px 10px;
  display: inline-block;
  background: #25cbd3;
  margin: 10px 0;
  color: #fff;
  font-size: 13px;
  border-radius: 15px;
}
.timeline .block .between {
  padding: 5px 10px;
  display: inline-block;
  color: #fff;
}
/*block marker */
.timeline-split .timeline .block::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: #25cbd3;
  top: 30px;
}
.timeline-split .timeline .block.block-left::after,
.timeline-split .timeline .block.block-right::after {
  content: "";
  width: 79px;
  height: 2px;
  position: absolute;
  background: #25cbd3;
  top: 34px;
  z-index: 0;
  right: -78px;
}
.timeline-split .timeline .block.block-right::after {
  left: -80px;
}
/*left block */
.timeline-split .timeline .block.block-left {
  text-align: right;
}
.timeline-split .timeline .block.block-left::before {
  right: -80px;
}
.timeline .block p {
  margin-bottom: 0;
}
/*right block */
.timeline-split .timeline .block.block-right {
  text-align: left;
  margin-left: 50%;
  margin-left: calc(50% + 74px);
}
.timeline-split .timeline .block.block-right::before {
  left: -80px;
}
/*decrease the timeline heading text */
@media (max-width: 992px) {
  .timeline-split .timeline {
    padding: 50px 20px;
  }

  .timeline-split .timeline h3 {
    font-size: 19px;
  }
}
/*change timeline layout to fit tiny screen size */
@media (max-width: 992px) {
  .timeline-split .timeline .block.block-left::after {
    left: -80px;
  }
  .timeline-split .timeline::after {
    left: 9px;
  }
  .timeline-split .timeline::before {
    left: 0;
  }
  .timeline-split .timeline .circle {
    left: 2px;
  }
  .timeline-split .timeline .block {
    width: 100% !important;
    text-align: left;
    padding-left: 20px;
  }
  .timeline-split .timeline .block::before {
    left: -15px;
  }
  .timeline-split .timeline .block.block-left {
    text-align: left;
  }
  .timeline-split .timeline .block.block-right {
    margin-left: 0;
  }
  .timeline-split .timeline .block.block-right::before {
    left: -15px;
  }
  .mt-30 {
    margin-top: 30px;
  }
}

/* Roadmap style 2*/
.timeline {
  position: relative;
  margin-bottom: 100px;
  z-index: 1;
}

.timeline:before {
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 1px;
  top: 0;
  border-right: 1px solid #3c3c3f;
  z-index: -1;
}

.timeline:after {
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  height: 100px;
  left: 1px;
  bottom: -105px;
  border-right: 1px dashed #3c3c3f;
  z-index: -1;
}

.timeline .date-title {
  text-align: center;
  margin: 0px 0 50px;
}

.timeline .date-title span {
  padding: 15px 30px;
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  background: #aa0b22;
  border-radius: 5px;
}

.news-item {
  padding-bottom: 45px;
}

.news-item.right {
  float: right;
  margin-top: 40px;
}

.news-item .news-content {
  margin: 20px 30px 0 0;
  position: relative;
  padding: 30px;
  padding-left: 100px;
  background: #eaeaea;
  border-radius: 10px;
  box-shadow: -5px 5px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.news-item:hover .news-content {
  background: #aa0b22;
  color: #fff;
}

.news-item.right .news-content {
  margin: 20px 0 0 30px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.08);
}

/* .news-item .news-content:after {
  display: block;
  content: "";
  position: absolute;
  top: 50px;
  right: -40px;
  width: 0px;
  height: 0px;
  background: transparent;
  border: 20px solid transparent;
  border-left: 20px solid #f5f5f5;
  -webkit-transition: border-left-color 0.3s ease-out;
  transition: border-left-color 0.3s ease-out;
}

.news-item.right .news-content:after {
  position: absolute;
  left: -40px;
  right: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid #f5f5f5;
  -webkit-transition: border-right-color 0.3s ease-out;
  transition: border-right-color 0.3s ease-out;
}

.news-item:hover .news-content:after {
  border-left-color: #3c3c3f;
}

.news-item.right:hover .news-content:after {
  border-left-color: transparent;
  border-right-color: #3c3c3f;
}

.news-item .news-content:before {
  display: block;
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -55px;
  top: 60px;
  background: #3c3c3f;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.news-item.right .news-content:before {
  left: -55px;
  right: auto;
} */

.news-content .date {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 10px;
  text-align: center;
  color: #aa0b22;
  -webkit-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.news-item:hover .news-content .date {
  color: #fff;
}

.news-content .date p {
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

.news-content .date small {
  margin: 0;
  display: block;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
}

.news-content .news-title {
  font-size: 24px;
  font-weight: 300;
}

.news-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  color: #000;
}

.news-content .read-more,
.news-content .read-more:hover,
.news-content .read-more:active,
.news-content .read-more:focus {
  padding: 10px 0;
  text-decoration: none;
  font-size: 16px;
  color: #7a7c7f;
  line-height: 24px;
}

.news-item:hover .news-content .read-more,
.news-item:hover .news-content .read-more:hover,
.news-item:hover .news-content .read-more:active,
.news-item:hover .news-content .read-more:focus {
  color: #fff;
}

.news-content .read-more {
  -webkit-transition: padding 0.3s ease-out;
  transition: padding 0.3s ease-out;
}

.news-content .read-more:hover {
  padding-left: 7px;
}

.news-content .read-more:hover:after {
  padding-left: 20px;
}

.news-item:hover .news-content .read-more:after {
  color: #fff;
}

.news-content .news-media {
  position: absolute;
  width: 80px;
  bottom: -45px;
  right: 40px;
  border-radius: 8px;
}

.news-content .news-media img {
  border-radius: 8px;
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.news-content .news-media a {
  display: block;
  text-decoration: none;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.news-content .news-media a:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.news-content .news-media a:after {
  content: "\f065";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-family: FontAwesome;
  font-size: 32px;
  line-height: 80px;
  text-align: center;
  color: #3c3c3f;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.2s ease-out 0.1s;
  transition: all 0.2s ease-out 0.1s;
}

.news-content .news-media.video a:after {
  content: "\f04b";
}
/* ##### 7.0 Video Area CSS ##### */

.mfp-wrap {
  z-index: 6000;
}

.mfp-bg {
  z-index: 5500;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  right: 0;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  padding-right: 0;
}

.mfp-iframe-holder .mfp-close {
  top: 0;
}

/* ##### 9.0 Gallry Item Area CSS ##### */

.single_gallery_item {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 30px;
}

.gallery-hover-overlay {
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  top: 0;
  left: 15px;
  z-index: 10;
  background-color: rgba(72, 52, 212, 0.8);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  visibility: hidden;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  padding: 30px;
  text-align: center;
}

.single_gallery_item:hover .gallery-hover-overlay {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;
}

.portfolio-menu button {
  line-height: 1;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  text-transform: uppercase;
  padding: 8px 15px 5px;
  border-radius: 30px;
}

.portfolio-menu button.active {
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.port-more-view > a {
  color: #fff;
}

.port-hover-text h3 {
  font-size: 14px;
  margin-bottom: 0;
}

.port-hover-text > a {
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
}

/* ##### 10.0 Cool Fact Area CSS ##### */

.cool-facts-area {
  padding: 100px 0 70px;
}

.single_cool_fact {
  position: relative;
  z-index: 1;
  background: #0d003b;
  border: 1px solid #25cbd3;
  border-bottom: 3px solid #25cbd3;
  border-radius: 0 0 20px 20px;
  padding: 30px;
  overflow: hidden;
  margin-bottom: 30px;
}

.cool_fact_icon i {
  font-size: 20px;
  margin-top: 15px;
  color: #fff;
}

.cool_fact_detail h3 {
  font-size: 20px;
  margin-top: 15px;
}

.cool_fact_detail h2 {
  font-size: 12px;
  margin-bottom: 0;
  text-transform: uppercase;
}

/* ##### 11.0 Price Table Area CSS ##### */
.pricing-item {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border: solid 1px rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 30px 0 20px;
  margin: 0 0 30px;
  cursor: pointer;
}
.pricing-item h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.8px;
  color: #00e0c4;
  margin: 0 0 20px;
}
.pricing-item h3 {
  font-size: 52px;
  font-weight: 400;
  line-height: 0.92;
  margin: 0 0 10px;
}
.pricing-item .pricing {
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 20px;
  color: #fff;
}
.pricing-item span {
  font-size: 14px;
  display: inline-block;
  color: #00e0c4;
  margin: 0 0 20px;
}
.pricing-item label {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  width: 100%;
  color: #fff;
  font-weight: normal;
  letter-spacing: 0.5px;
  background-image: linear-gradient(117deg, #724eff, #5187ff);
  background-repeat: no-repeat;
  background-position: left;
  background-image: url(../img/svg/discount-bg-1.svg);
}

.single_price_table_content {
  padding: 50px;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  border-radius: 6px;
}

.single_price_table_content.active {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15);
}

.price_table_text {
  margin-bottom: 45px;
}

.price_table_text > h5 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.price_table_text > h1 {
  font-size: 48px;
  margin-bottom: 25px;
  line-height: 1;
  color: #3964f9;
}

.price_table_text > p,
.table_text_details > p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1;
}

.table_text_details > p {
  margin-bottom: 20px;
}

/* ##### 12.0 testimonial Area CSS ##### */
.single-testimonial {
  border-radius: 15px;
  border: 1px solid #eee;
  margin: 0 15px;
  padding: 30px 20px;
  /* background-image: url(../img/core-img/cards-bg.png); */
}
.testimonial-description {
  position: relative;
  z-index: 2;
  background-color: transparent;
  text-align: center;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.testimonial_image {
  height: 100px;
  width: 100px;
  margin: auto;
}

.testimonial_image > img {
  border-radius: 50%;
}

.testimonial_text > p {
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 25px;
  font-size: 14px;
}

.admin_text > h5 {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 5px;
}

.admin_text > p {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

.client_slides .owl-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 50px;
}

.client_slides .owl-dot {
  margin: 0 5px;
  line-height: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.client_slides .owl-dot.active {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.15);
}

/* ##### 13.0 Team Area CSS ##### */

.team-item {
  padding: 35px;
  padding-right: 0;
  position: relative;
  z-index: 0;
}

.team-item:after,
.team-item:before {
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
  box-shadow: 0 5px 30px 0 rgba(39, 39, 39, 0.15);
  border-radius: 10px;
  z-index: -1;
}

.team-item:before {
  height: 100%;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-item:after {
  height: 6px;
  width: calc(100% - 30px);
  background-color: #aa0b22;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.team-item:hover:after {
  height: 100%;
}

.team-item .img-holder {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.2);
  box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.2);
  border-radius: 10px;
  width: calc(100% - 70px);
  margin-left: 70px;
  overflow: hidden;
}

.team-item .img-holder img {
  border-radius: 10px;
  width: 100%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.team-item:hover .img-holder img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.team-item .socials {
  position: absolute;
}

.team-item .socials a {
  display: block;
  margin-right: 0;
  margin-bottom: 15px;
  -webkit-box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.12);
}

.team-item .socials a:last-of-type {
  margin-bottom: 0;
}

.team-item .team-content {
  margin-right: 70px;
  text-align: center;
}

.team-item .team-content h5 {
  color: #101f41;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.team-item .team-content p {
  color: #aa0b22;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-weight: 600;
  font-size: 14px;
}

.team-item:hover .team-content h5,
.team-item:hover .team-content p {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.d-inline-block {
  display: inline-block !important;
}

.socials a {
  width: 35px;
  height: 35px;
  background-color: #aa0b22;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 2px 0 #ad4454;
  box-shadow: 0 3px 2px 0 #ad4454;
  text-align: center;
  color: #fff;
  padding-top: 10px;
  font-size: 16px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*
* ----------------------------------------------------------------------------------------
*  START Subscribe STYLE
* ----------------------------------------------------------------------------------------
*/
.subscribe {
  position: relative;
}
.subscribe .section-heading > p {
  color: #eee;
}

.subscribe .section-heading {
  margin-bottom: 30px;
}

.subscribe .service-text {
  padding-top: 0;
}
.subscribe .title-box {
  margin-bottom: 30px;
}
.subscribe-section {
  padding: 15px;
  display: inline-block;
  background: #fff;
}
.subscribe-section .input-wrapper {
  position: relative;
  display: inline-block;
}
.subscribe-section input {
  padding: 10px 10px 10px 40px;
  position: relative;
  border: 2px solid #aa0b22;
  background: transparent;
  min-width: 350px;
  border-radius: 5px;
}
.subscribe-section input:focus {
  border-radius: 5px;
  outline: none;
  border-color: #df0a1b;
}
.subscribe-section i {
  font-size: 20px;
  color: #df0a1b;
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 15px;
}

.subscribe-wrapper {
  padding: 60px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 20px;
  background: url(../img/core-img/shape.png) #000 center center no-repeat;
  background-size: cover;
  /* box-shadow: 0 10px 80px rgba(15, 62, 153, 0.3); */
}
.telegram-text {
  font-size: 20px;
  color: #fff;
  margin-right: 10px;
  position: relative;
  z-index: 2;
}

.social-list {
  display: inline-block;
  margin-bottom: 0;
  height: 40px;
  position: relative;
  z-index: 2;
}
.social-list li {
  float: left;
  padding: 0 14px;
}
.social-list li a {
  font-size: 34px;
  color: #fff;
  opacity: 0.8;
}
.social-list li a:hover {
  opacity: 1;
}
.subscribe .buy-tokens i {
  margin-right: 10px;
}
.subscribe .dream-btn {
  position: absolute;
  top: -2px;
  right: -10px;
  min-width: 48px;
  height: 48px;
  padding: 0;
  border-color: #fff;
}
.subscribe .dream-btn:hover {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
.button {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: inline-block;
  padding: 14px 40px;
  font-size: 16px;
  z-index: 2;
  line-height: 1.25;
  color: #df0a1b;
  background: #fff;
  border: 0 solid;
  border-radius: 40px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 250ms all ease-in-out;
  box-shadow: 0 3px 21px 4px rgba(41, 41, 58, 0.2);
}
.button:hover {
  background: #df0a1b;
  color: #fff;
}
.button i {
  margin-right: 10px;
}
@media (max-width: 500px) {
  .subscribe-section input {
    min-width: 100%;
  }
  .subscribe-wrapper {
    padding: 60px 30px;
  }
  .subscribe-section {
    display: inherit;
  }
}
/*** 

====================================================================
    What we do
====================================================================

 ***/
@media (max-width: 767px) {
  .what-we-do {
    padding-bottom: 50px;
  }
}

.what-we-do .outer {
  position: relative;
  padding-top: 20px;
}

.what-we-do .outer .inner-circle {
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: 50%;
  margin-top: -150px;
  width: 300px;
  height: 300px;
  border: 1px dashed #d0d0d0;
  line-height: 300px;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
}
.what-we-do .outer .phone {
  position: absolute;
  width: 100%;
  left: 50%;
  top: -110px;
  transform: translateX(-50.5%);
}

.what-we-do .outer:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: -250px;
  width: 500px;
  height: 500px;
  border: 1px solid #f5f5f5;
  border-radius: 50%;
}

.what-we-do .outer .header-wraperumn {
  float: right;
}

.service-box-three {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

.what-we-do .outer .service-box-three {
  margin-bottom: 50px;
}

.what-we-do .outer .service-box-three:first-child {
  left: 0px;
  top: 10px;
}

.what-we-do .outer .service-box-three:last-child {
  margin-bottom: 0px;
  left: 0px;
  top: 0px;
}

.service-box-three .inner-box {
  position: relative;
  display: block;
  text-align: right;
  padding-right: 0px;
}

.service-box-three .icon-box {
  position: relative;
  right: 0px;
  top: 0px;
  margin-bottom: 20px;
  width: 72px;
  height: 72px;
  text-align: center;
  line-height: 58px;
  color: #fff !important;
  font-size: 30px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.service-box-three .icon-box img {
  max-width: 35px;
}
.service-box-three h3 {
  position: relative;
  line-height: 1.6em;
  font-size: 17px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 600;
}

.service-box-three h3 a {
  position: relative;
  color: #2a2a2a;
}

.service-box-three .text {
  position: relative;
  line-height: 1.7em;
  color: #888;
}

.service-box-four {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

.what-we-do .outer .service-box-four {
  margin-bottom: 50px;
}

.what-we-do .outer .service-box-four:last-child {
  margin-bottom: 0px;
  top: 0px;
}

.service-box-four .inner-box {
  position: relative;
  display: block;
  text-align: left;
  padding-left: 0px;
}

.service-box-four .icon-box {
  position: relative;
  left: 0px;
  top: 0px;
  margin-bottom: 20px;
  width: 72px;
  height: 72px;
  text-align: center;
  line-height: 58px;
  color: #fff !important;
  font-size: 30px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #7450fe 0%, #21d397 100%);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}
.service-box-four .icon-box img {
  max-width: 35px;
}
.service-box-four h3 {
  position: relative;
  line-height: 1.6em;
  font-size: 17px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 600;
}

.service-box-four h3 a {
  position: relative;
  color: #2a2a2a;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.service-box-four .text {
  position: relative;
  line-height: 1.7em;
  color: #888;
}

@media only screen and (max-width: 1200px) {
  .what-we-do .outer .inner-circle {
    display: none;
  }

  .what-we-do .outer .service-box-three:first-child,
  .what-we-do .outer .service-box-three:last-child,
  .what-we-do .outer .service-box-four:first-child,
  .what-we-do .outer .service-box-four:last-child {
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .what-we-do .outer:before,
  .what-we-do .outer:after {
    display: none;
  }
  .what-we-do .outer .service-box-three,
  .what-we-do .outer .service-box-four {
    padding: 0 20px;
  }
  .what-we-do .outer .service-box-three:last-child,
  .what-we-do .outer .service-box-four:last-child,
  .what-we-do .outer .service-box-three,
  .what-we-do .outer .service-box-four {
    margin-bottom: 50px;
  }

  .service-box-three .inner-box,
  .service-box-four .inner-box {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
  }

  .service-box-three .icon-box,
  .service-box-four .icon-box {
    position: relative;
    display: block;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 auto 30px;
  }
}

/* ##### 14.0 Our Blog Area CSS ##### */

.single-blog-area {
  position: relative;
  z-index: 1;
  margin-bottom: 100px;
}

.post-meta p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-transform: uppercase;
}

.post-meta p a {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 30px;
}

.post-title {
  margin-bottom: 20px;
  display: block;
}

.blog_thumbnail img {
  width: 100%;
}

.single-blog-area blockquote {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 3px;
  margin: 30px 0;
  display: block;
}

.single-blog-area blockquote span {
  margin-bottom: 0;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}

.comment_area {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 50px;
  padding-bottom: 50px;
}

.comment_area .title {
  margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .comment_area .comment-content {
    padding: 20px 15px;
  }
}

.comment_area .comment-content .comment-author {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 79px;
  flex: 0 0 79px;
  min-width: 79px;
  margin-right: 55px;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .comment_area .comment-content .comment-author {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    min-width: 60px;
    margin-right: 15px;
  }
}

.comment_area .comment-content .comment-author img {
  border-radius: 50%;
}

.comment_area .comment-content .comment-meta {
  margin-bottom: 30px;
}

.comment_area .comment-content .comment-meta .post-date {
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0;
  display: block;
}

.comment_area .comment-content .comment-meta .post-author {
  margin-bottom: 15px;
  display: block;
  color: #fff;
}

.comment_area .comment-content .comment-meta p {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 2;
}

.comment_area .comment-content .comment-meta .comment-reply {
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
}

.comment_area .single_comment_area {
  margin-bottom: 30px;
}

.comment_area .single_comment_area:last-of-type {
  margin-bottom: 0;
}

.comment_area .children .single_comment_area {
  margin-left: 50px;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .comment_area .children .single_comment_area {
    margin-left: 15px;
  }
}

.single_comment_area .children .single_comment_area .comment-meta {
  margin-bottom: 0;
}

/* ##### Contact Area CSS ##### */

.group {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.group input,
.group textarea {
  font-size: 12px;
  font-style: italic;
  padding: 10px;
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  background-color: transparent;
  color: #fff;
  border-radius: 0;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.group input:focus,
.group textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.group label {
  color: #fff;
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  margin-bottom: 0;
}

.group .bar {
  position: relative;
  display: block;
  width: 100%;
}

.group .bar:before,
.group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
}

.group .bar:before {
  left: 50%;
}

.group .bar:after {
  right: 50%;
}

.group textarea {
  height: 130px;
}

/* Form Active State */

.group input:focus ~ label,
.group textarea:focus ~ label,
.group input:valid ~ label,
.group textarea:valid ~ label {
  top: -17px;
  font-size: 12px;
  color: #fff;
}

.group input:focus ~ .bar:before,
.group textarea:focus ~ .bar:before,
.group input:focus ~ .bar:after,
.group textarea:focus ~ .bar:after {
  width: 50%;
  background-color: #fff;
}

input:required,
textarea:required {
  box-shadow: none !important;
}

/* ##### Footer Area ##### */

.footer-area {
  position: relative;
  z-index: 0;
  padding: 0;
  background-color: #0f0f0f;
  /* #3f0e61 */
}

.footer-content-area {
  padding: 140px 0 80px;
  /* background: url(../img/bg-img/footer-bg.png) no-repeat top center; */
  background-size: cover;
}
.footer-content-area.demo {
  margin-top: 70px;
}
.footer-logo {
  margin-bottom: 15px;
}
.footer-logo a {
  color: #fff;
  font-size: 20px;
}
.footer-logo img {
  width: 40px;
}
.footer-side-thumbnail {
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  background-position: top center;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}

.copywrite_text > p {
  margin-bottom: 10px;
  color: #fff;
  font-size: 13px;
}

.copywrite_text > p > a {
  color: #fff;
}

.footer-social-info a i {
  font-size: 14px;
  color: #fff;
  margin-right: 15px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.footer-social-info a i:hover {
  color: #4a7aec;
}

.contact_info_area .contact_info {
  text-align: left !important;
}

.contact_info_area .contact_info h5 {
  font-size: 14px;
}

.contact_info_area .contact_info p {
  margin-bottom: 0;
  font-size: 14px;
  color: #ddd;
}
.contact_info_area .contact_info a:hover p {
  color: #fff;
}

/* ##### Breadcumb Area ##### */

.breadcumb-area {
  position: relative;
  z-index: 1;
  height: 400px !important;
}

.breadcumb-content {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/bg-img/wavy-bg.png) no-repeat center;
  background-size: cover;
}

.breadcumb--con {
  padding-top: 90px;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcumb--con .title {
  font-size: 42px;
  margin-bottom: 15px;
  margin-left: 0;
}

.breadcrumb-item,
.breadcrumb-item.active,
.breadcrumb-item > a {
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-link {
  color: #fff;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin: 0 2px;
}

/* ##### Sidebar CSS ##### */

.search-widget-area form {
  position: relative;
  z-index: 1;
}

.search-widget-area form input {
  width: 100%;
  height: 45px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  padding: 0 15px;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
}

.search-widget-area form button {
  width: 60px;
  height: 45px;
  background-color: transparent;
  padding: 0 15px;
  color: #fff;
  font-size: 14px;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.widget-title h5 {
  margin-bottom: 30px;
  font-size: 18px;
  text-transform: capitalize;
  border-bottom: 1px solid #fff;
  padding: 0 0 5px 0;
}

.dont-miss-post-content > a {
  font-size: 16px;
  color: #fff;
  display: block;
  margin-top: 15px;
}

.dont-miss-post-content > span {
  font-size: 12px;
  color: #fff;
  display: block;
  text-transform: uppercase;
}

.subscribe-form input {
  width: 100%;
  height: 45px;
  border-radius: 45px;
  border: none;
  padding: 0 20px;
  font-size: 12px;
  font-style: italic;
  color: #fff;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.1);
}

.subscribe-form button {
  width: 100%;
  height: 45px;
  border-radius: 45px;
  border: none;
  font-size: 12px;
  padding: 0;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

/* ##### Timeline CSS ##### */

.timelineBox {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
}

.timelineHeader {
  padding: 32px;
  padding: 32px;
  padding: 2rem;
  background: #e91e63;
  position: relative;
  z-index: 3;
}

.timelineHeader h3 {
  font-size: 32px;
  font-size: 32px;
  font-size: 2rem;
  margin: 0;
}

.timelineHeader h3 + span {
  font-size: 19.2px;
  font-size: 19.2px;
  font-size: 1.2rem;
  color: #fff;
}

.timelineBody {
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
}

.timelineBody .timeline {
  padding: 2em;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 2;
}

.timelineBody .timeline li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 1em 0;
  position: relative;
}
.timelineBody .timeline li:before {
  position: absolute;
  content: "";
  left: 7px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #25cbd3;
}

.timelineBody .timeline li:last-child {
  margin-bottom: 0;
}

.timelineBody .timeline .timelineDot {
  height: 15px;
  width: 15px;
  background: #fff;
  border-radius: 2rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15px;
  flex: 0 0 15px;
  margin-right: auto;
  margin-top: 6px;
  box-shadow: 0 0 8px #56cb5f;
}

.timelineBody .timeline .timelineDot:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 3px;
  height: 9px;
  width: 9px;
  background: #25cbd3;
  border-radius: 50%;
}

.timelineBody .timeline .timelineDate {
  font-size: 14px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  padding: 0;
}

.timelineBody .timeline .timelineDate p {
  color: #25cbd3;
}

.timelineBody .timeline .timelineWork {
  font-size: 16px;
  margin-left: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 65%;
  flex: 0 0 65%;
}

.timelineBody .timeline .timelineWork h6 {
  color: #fff;
}

.timelineBody .timeline .timelineWork span {
  display: block;
  color: #bdbdbd;
  font-size: 13px;
}

.lock {
  position: relative;
  overflow: hidden;
  padding-top: 27px;
  padding-top: 15px;
}
.lock img {
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin: 0px 20px;
}
.lock img:first-child {
  margin-left: 0;
}
.lock .ball {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 38px 4px 0 4px;
  vertical-align: middle;
  display: inline-block;
  float: left;
}
.lock .ball.ball_active {
  background: #1666ed;
  background: -moz-linear-gradient(45deg, #1666ed 0%, #57c6f3 100%);
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, #1666ed),
    color-stop(100%, #57c6f3)
  );
  background: -webkit-linear-gradient(45deg, #1666ed 0%, #57c6f3 100%);
  background: -o-linear-gradient(45deg, #1666ed 0%, #57c6f3 100%);
  background: -ms-linear-gradient(45deg, #1666ed 0%, #57c6f3 100%);
  background: linear-gradient(45deg, #1666ed 0%, #57c6f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1666ed', endColorstr='#57c6f3', GradientType=1 );
}
.lock .ball.ball_blue {
  background: #22047a;
}
/*
================================================
   Map style
================================================
*/

.spread-map {
  overflow: hidden;
}
.spread-map .sec-title {
  margin-bottom: 0;
}
.spread-map .counter-container {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .spread-map .map-container {
    margin-top: 30px;
  }
  .token-allocation .visa {
    margin-bottom: 30px;
  }
}
.spread-map .s-list-icon {
  position: absolute;
  top: 8px;
  padding: 7px;
  border: 1px solid;
  left: 0;
}
.spread-map .s-list-desc {
  padding-left: 45px;
}
.counter-wrapper {
  position: relative;
  margin-top: 20px;
  color: #222;
  z-index: 5;
}
.counter-wrapper .icon-box {
  position: relative;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  border: 2px solid #ffb426;
}
.counter-wrapper .icon-box img {
  position: absolute;
  max-width: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.counter-wrapper .counter {
  color: #ffb426;
  font-size: 20px;
  text-align: left;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 5px;
}
.counter-wrapper h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

/*
================================================
   map section style
================================================
*/
.download {
  position: relative;
  /* background-image: url(../img/svg/download-bg2.svg), url(../img/svg/download-bg3.svg), -webkit-linear-gradient(-73deg,#d787f5,#3634bb); */
  background-size: 894px 660px, 1005px 663px, 100%;
  box-shadow: rgba(6, 28, 66, 0.1) 0px 20px 40px;
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-position: calc(50% - 370px) 50%, calc(50% - 455px) 50%,
    center center;
}
@media (max-width: 992px) {
  .download {
    margin-top: 0px;
    /* background-image:  url(../img/svg/download-bg2.svg), -webkit-linear-gradient(-73deg,#d787f5,#3634bb);; */
    background-size: 100%, 100%;
    padding: 100px 0px;
    background-position: 50% 100%, center center;
  }
}
.download .dream-btn {
  border-radius: 0;
  height: 55px;
  line-height: 52px;
  font-size: 14px;
}
.download .dream-btn img {
  padding-right: 10px;
}
.spread-map .map-container {
  position: relative;
}
.indicator {
  position: absolute;
  z-index: 9;
  width: 2.2em;
  height: 2.2em;
  cursor: pointer;
}

.indicator-item {
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #e35583;
  -webkit-animation: pulse 0.6s infinite alternate;
  animation: pulse 0.6s infinite alternate;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(0.5, 0.5, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.indicator:hover .indicator-item {
  border-color: #071e33;
}

.indicator:first-child {
  top: 10%;
  left: 40%;
}

.indicator:nth-child(2) {
  top: 19%;
  left: 13%;
}

.indicator:nth-child(3) {
  top: 40%;
  left: 50%;
}

.indicator:nth-child(4) {
  top: 20%;
  left: 70%;
}

.indicator-content {
  position: absolute;
  z-index: 99;
  width: 250px;
  left: 50%;
  bottom: 100%;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  font-weight: 400;
  color: #fffaf0;
  background: transparent;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  font-family: "open-sans", cursive;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;
}

.indicator:hover .indicator-content {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.indicator-content span {
  display: block;
}

.indicator-text {
  border-bottom: 3px solid #ffb426;
  overflow: hidden;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
}

.indicator:hover .indicator-text {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.indicator-inner {
  background: #1e3953;
  padding: 10px 15px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.indicator:hover .indicator-inner {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.s-list li {
  margin-bottom: 10px;
  color: #777;
  position: relative;
  font-size: 15px;
}
.s-list span.fa {
  font-size: 16px;
  color: #aa0b22;
  margin-right: 10px;
}
.spread-map .s-list li {
  min-height: 50px;
}
.spread-map .single-service-item {
  min-height: 519px;
}
.hotel-item {
  position: relative;
  border-radius: 10px;
}
.hotel-item .review {
  position: absolute;
  padding: 5px 9px;
  top: 15px;
  right: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #0066d6;
  border-radius: 5px;
}
.hotel-info {
  position: absolute;
  width: 100%;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  padding: 25px 20px 5px;
  bottom: 0;
  left: 0;
}
.hotel-info h6 {
  color: #fff !important;
  text-align: left;
}
.hotel-item .stars i {
  font-size: 12px;
  color: #fff;
}

/*
* ----------------------------------------------------------------------------------------
*  START counter-down STYLE
* ----------------------------------------------------------------------------------------
*/
.ico-counter {
  background-image: -webkit-linear-gradient(73deg, #d787f5, #3634bb);
  background-image: linear-gradient(73deg, #d787f5, #3634bb);
  padding: 45px 40px;
  border-radius: 20px;
}
.ico-counter.dotted-bg {
  /* background: url(../img/bg-img/dotted-bg.jpg) no-repeat; */
  background-size: cover;
}
.counter-map {
  position: relative;
}
.ico-counter.mapy {
  position: absolute;
  min-width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .ico-counter {
    margin-bottom: 30px;
    padding: 45px 15px;
  }
  .ico-counter.mapy {
    min-width: 60%;
  }
}
.ico-counter .dream-btn,
.ico-counter .more-btn {
  min-width: 205px;
}
.counter-down {
  position: relative;
}
.conuter-header {
  overflow: hidden;
  position: relative;
}

.timer-body-block {
  display: flex;
}
.count-down .table-cell {
  position: relative;
  width: 25%;
}
.count-down .tab-val {
  width: 90%;
  font-size: 30px;
  font-weight: 500;
  height: 75px;
  line-height: 75px;
  margin: 0 auto;
  background-color: #190345;
  color: #ffffff;
}
.count-down .tab-metr {
  margin-top: 15px;
  font-size: 16px;
  color: #ffffff;
}
@media (max-width: 480px) {
  .count-down .tab-metr {
    font-size: 14px;
  }
}
.conuter-header h3 {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
}
.conuter-header h4 {
  font-size: 18px;
  text-transform: uppercase;
}
.counterdown-content {
  padding: 30px 0 0;
}
.clock-wrapper {
  position: relative;
  background: #fff;
  padding: 30px 0 15px 9px;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid;
}
.dollar-earning {
  font-size: 24px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
}
.btc-earning {
  font-size: 24px;
  font-weight: 600;
  padding-right: 45px;
  color: #fff;
  position: relative;
}
.ico-sales-status {
  overflow: hidden;
}
.ico-sales-status p {
  font-size: 12px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.62);
}
.btc-earning span {
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 6px;
}

.ico-progress {
  margin: 20px 10px;
}
.ico-progress ul {
  margin-bottom: 5px;
}
.ico-progress li {
  font-size: 18px;
  font-weight: 400;
}
.ico-progress li.title {
  float: left;
  padding-left: 30px;
  font-weight: 500;
  color: #fff;
}
.ico-progress li.strength {
  float: right;
  font-weight: 500;
  color: #fff;
}
.ico-progress .current-progress {
  width: 100%;
  height: 16px;
  position: relative;
  background: rgba(191, 191, 191, 0.6);
  border-radius: 7px;
}
.ico-progress .current-progress:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 26px;
  bottom: -5px;
  left: 12%;
  background: #fff;
}

.current-progress .progress-bar {
  border-radius: 7px;
  height: 100%;
  background-image: -webkit-linear-gradient(left, #fb881d 0%, #ffad34 100%);
  background-image: -o-linear-gradient(left, #fb881d 0%, #ffad34 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fb881d),
    to(#ffad34)
  );
  background-image: linear-gradient(to right, #fb881d 0%, #ffad34 100%);
}
.ico-progress span {
  color: #e8e0f3;
  font-size: 12px;
  font-weight: 700;
  padding-top: 7px;
  display: inline-block;
}
.doc-element {
  background-color: #1d025c;
  border-radius: 4px;
  border-bottom: 2px solid #25cbd3;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.doc-element:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  transition: 0.3s;
  right: 15px;
  width: 27px;
  height: 34px;
  /* background: url(../img/svg/pdf.svg) 50% no-repeat; */
  background-size: contain;
}
.doc-element:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  transition: 0.3s;
  right: 15px;
  width: 27px;
  height: 30px;
  /* background: url(../img/svg/view.svg) 50% no-repeat; */
  background-size: contain;
  opacity: 0;
}
.doc-element .document-entry .title {
  font-size: 14px;
  color: #fff;
  line-height: 1.35;
}
.doc-element:hover {
  background-color: #25cbd3;
}
.doc-element:hover:after {
  opacity: 1;
}

.ico-info-table {
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid #eee;
  box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
  -webkit-box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.18);
}
/* ##### Accordians CSS ##### */

.dream-faq-area dl {
  width: 100%;
}

.dream-faq-area dt {
  cursor: pointer;
  position: relative;
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
  color: #fff;
  background: #3c3c3f;
  font-weight: 500;
  margin-bottom: 20px;
  border-radius: 5px;
}
.dream-faq-area dt::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  bottom: -8px;
  right: -6px;
  border-radius: 5px;
  border: 1px solid #eee;
  z-index: -1;
}
.dream-faq-area dt:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.dream-faq-area dd {
  margin: 0;
  padding: 15px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* ##### Button Effects ##### */

.ripple {
  position: absolute;
  height: 0.25em;
  width: 0.25em;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: -1;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform-origin: center 50%;
  transform-origin: center 50%;
  -webkit-transition: opacity 1.6s;
  transition: opacity 1.6s;
  -webkit-animation: ripple 1.6s;
  animation: ripple 1.6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.ripple-active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  100% {
    -webkit-transform: scale3d(50, 50, 50);
    transform: scale3d(50, 50, 50);
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  100% {
    -webkit-transform: scale3d(50, 50, 50);
    transform: scale3d(50, 50, 50);
  }
}

/* ##### Animated Headline CSS ##### */

.cd-intro.default-title > h2 {
  font-size: 50px;
}

.cd-headline.clip b,
.cd-words-wrapper b {
  font-weight: 500;
}

.cd-headline {
  font-weight: 500;
}

/* ##### particless CSS ##### */

#particles-js {
  height: 900px;
  width: 100%;
  position: relative;
  z-index: 1;
}

#particles-js canvas.particles-js-canvas-el {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

/* ##### Snow Version CSS ##### */

.snow-content-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.fullwidth {
  width: 100vw !important;
  height: 100vh !important;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .welcome_area.ico {
    height: 1200px !important;
  }
}
/* ##### Slider Area CSS ##### */

.welcome_area .welcome_slides .owl-prev,
.welcome_area .welcome_slides .owl-next {
  color: #fff;
  font-size: 24px;
  left: -100px;
  margin-top: -44px;
  position: absolute;
  text-align: center;
  top: calc(50% + 90px);
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  z-index: 99;
}

.welcome_area .welcome_slides .owl-next {
  right: -100px;
  left: auto;
}

.welcome_area .welcome_slides:hover .owl-next {
  right: 30px;
}

.welcome_area .welcome_slides:hover .owl-prev {
  left: 30px;
}

/* ##### Flying Bird CSS ##### */

.bird {
  background-size: cover;
  width: 352px;
  height: 500px;
  -webkit-animation: fly-cycle 1s -0.5s steps(10) infinite;
  animation: fly-cycle 1s -0.5s steps(10) infinite;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -3600px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -3600px 0;
  }
}

.bird {
  position: absolute;
  top: 23%;
  left: 10%;
  z-index: -1;
}

/* ##### Subscribe form area CSS ##### */

.welcome-content .subscribe-email {
  width: 70%;
  height: 55px;
  border: none;
  padding: 0 25px;
  border-radius: 8px;
  font-size: 12px;
  font-style: italic;
}

/* ##### Light Version CSS ##### */

body.light-version {
  background: #fff !important;
}

.light-version p {
  color: #888;
}

.light-version h2,
.light-version h3,
.light-version h4,
.light-version h5,
.light-version h6,
.light-version .growing-company p .counter,
.light-version .service_single_content .service_icon i,
.light-version .portfolio-menu button,
.light-version .post-meta p a,
.light-version .group label,
.light-version input:focus ~ label,
.light-version textarea:focus ~ label,
.light-version input:valid ~ label,
.light-version textarea:valid ~ label,
.light-version .copywrite_text > p > a {
  color: #222;
}

.light-version .dream-faq-area dt {
  color: #fff;
  border: 1px solid #f2f4f8;
  background-image: linear-gradient(to right, #0f0f0f 0%, #f17674 100%);
  background-image: -webkit-linear-gradient(to right, #0f0f0f 0%, #f17674 100%);
  border-radius: 25px;
  margin-bottom: 15px;
}
.light-version .dream-faq-area dt.v2 {
  background-image: linear-gradient(to right, #21d397 0%, #7450fe 100%);
}
.light-version .dream-faq-area dt:first-child {
  border-top: 1px solid #f2f4f8;
}

.light-version .timelineBox {
  background: #f2f4f8;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.light-version .single-mission h6,
.light-version .single-mission p,
.light-version .welcome-content h2,
.light-version .cool_fact_detail h3,
.light-version .cool_fact_detail h2,
.light-version .cta-content h2,
.light-version .cta-content p,
.contact_info_area .contact_info h5 {
  color: #fff;
}

.light-version .portfolio-menu button.active {
  color: #222;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.light-version .single-team-member {
  padding: 30px;
}
.light-version .single-team-member:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-image: linear-gradient(to right, #0f0f0f 0%, #f17674 100%);
  background-image: -webkit-linear-gradient(to right, #0f0f0f 0%, #f17674 100%); */
  content: "";
  z-index: -5;
}
.light-version .single-team-member.v2:after {
  /* background: #f4f9ff; */
}
.light-version .faq-timeline-area {
  /* background: url('../img/bg-img/bread-bg.png') no-repeat center center; */
  background-size: cover;
}
.light-version .trust-item {
  border: 1px solid #ddd;
}
.light-version #scrollUp {
  color: #000;
}
.light-version .subscribe h2,
.light-version .demo-video h2,
.light-version .subscribe .group label,
.light-version .subscribe .group input {
  color: #fff;
}
.light-version .subscribe .group input {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}
.light-version .demo-video {
  background: linear-gradient(to right, #df0a1b, #aa0b22);
  overflow: hidden;
  border-top: 1px solid #eee;
}
.light-version .demo-video p {
  color: rgba(255, 255, 255, 0.7);
}
.light-version .our_services_area {
  padding-bottom: 70px;
}

.light-version .service_single_content {
  border-radius: 10px;
  background-color: #fff;
  /* background-image: url(../img/core-img/cards-bg.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.11);
  box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.11);
  border: none;
  padding: 40px 20px;
  margin-bottom: 30px !important;
}
.light-version .service_single_content p {
  margin-bottom: 0;
}
.light-version .services-block-four .inner-box h3 a {
  color: #222;
}
.token .service_single_content {
  background-image: none;
  padding: 0;
  box-shadow: none;
  background: none;
}
.token .service_single_content .service_icon {
  max-width: 150px;
  height: 150px;
}
.token.v2 .service_single_content .service_icon {
  max-width: 60px;
  height: 60px;
  box-shadow: 0px 2px 27px 0px rgba(154, 161, 171, 0.54);
}
.token .service_single_content .service_icon img {
  height: 100%;
}
.fuel-features {
  position: relative;
  overflow: hidden;
}
.fuel-features:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 70%;
  left: 0;
  bottom: 0;
  /* background: url(../img/bg-img/screen6.jpg) no-repeat center center; */
  background-size: cover;
  z-index: -1;
}
.fuel-features .container.has-shadow {
  padding: 50px 30px 20px;
  background: #100f43;
}
.light-version #scrollUp:before {
  position: absolute;
  width: 30%;
  height: 2px;
  background-color: #000;
  content: "";
  top: 10px;
  right: 100%;
  z-index: -200 !important;
}
.light-version .our_blog_area {
  background: #edf6fd;
}
.light-version .group input,
.light-version .group textarea {
  color: #222 !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.light-version .group input:focus ~ .bar:before,
.light-version .group textarea:focus ~ .bar:before,
.light-version .group input:focus ~ .bar:after,
.light-version .group textarea:focus ~ .bar:after {
  background-color: #222;
}

.light-version .client_slides .owl-dot {
  color: #222;
  background-color: transparent;
}

.light-version .client_slides .owl-dot.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

/* demo page */
.demo-item {
  -webkit-box-shadow: 0 2px 28px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 28px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #fff;
  margin-bottom: 30px;
  transition: all 0.3s ease-in;
}

.demo-item .demo-badge {
  position: absolute;
  top: 20px;
  right: 0;
  transition: all 0.3s ease-in-out;
  background: #ff699a;
  font-size: 14px;
  color: #fff;
  border-radius: 15px 0 0 15px;
  font-weight: 600;
  padding: 5px 15px;
  transition: all 0.3s ease-in;
}
@media (min-width: 1200px) {
  .demo .container {
    max-width: 1280px;
  }
  .simple-numbers-wrapper {
    margin-top: 150px;
  }
  .hero-section .illusto img {
    max-width: 140%;
    margin-left: -100px;
    margin-top: 60px;
  }
  .hero-section .illusto-1 img {
    max-width: 100%;
    margin-left: 0px;
    margin-top: 60px;
  }
  .hero-section .illusto-2 img {
    max-width: 160%;
    margin-left: -150px;
    margin-top: 0px;
  }
  .hero-section .curved {
    max-width: 100%;
    margin-top: 30px;
  }
}
.demo-item:hover {
  -webkit-box-shadow: 0 1px 28px rgba(255, 152, 0, 0.66);
  box-shadow: 0 1px 28px rgba(255, 152, 0, 0.66);
  transform: translate(0, -9px);
  -webkit-transform: translate(0, -9px);
}

.preview-link {
  text-align: center;
}
.preview-demo {
  position: relative;
  display: inline-block;
  padding: 20px 30px;
  width: 100%;
  font-weight: 700;
  color: #673ab7;
  text-transform: uppercase;
  border: 1px solid #673ab7;
}
.preview-demo i {
  margin-left: 10px;
}
.preview-demo:hover {
  color: #333;
}

.ico-counter {
  background-image: linear-gradient(73deg, #d787f5, #3634bb);
  padding: 40px;
  border-radius: 20px;
}

.count-down .table-cell {
  position: relative;
  width: 25%;
}
.count-down .tab-val {
  width: 90%;
  font-size: 20px;
  font-weight: 500;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  background-color: #290571;
  color: #ffffff;
}
.count-down .tab-metr {
  margin-top: 4px;
  font-size: 11px;
  margin-bottom: 15px;
  color: #ffffff;
}
@media (max-width: 480px) {
  .count-down .tab-metr {
    font-size: 14px;
  }
}

.slideCountdown {
  display: flex !important;
  font-family: Courier;
  font-weight: bold;
  justify-content: center;
  color: #fff;
}

.slide-block-time {
  font-size: 30px;
  min-width: 24%;
  margin: 0;
  float: left;
  font-size: 25px !important;
  width: 30px !important;
  margin: 0px !important;
}

.slide-block-time:nth-child(2) {
  margin: 0 10px !important;
}

.slide-block-time:nth-child(3) {
  margin-right: 10px !important;
}

.wrap-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #290571;
  padding: 20px;
}

.slide-block-time .wrap-stage > .stage {
  width: 20px;
  overflow: hidden;
  height: 40px;
  position: relative;
}

.slide-block-time > .title {
  font-size: 10px;
  font-weight: normal;
  margin-top: 5px;
  text-transform: lowercase;
  /*color: transparent;*/
}

.ico-progress .current-progress {
  background-color: transparent !important;
}
