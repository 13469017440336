.loader__dummy {
  height: "300px";
  width: "300px";
}

.cancel__wrapper {
  position: "relative";
  width: 100%;

  /* display: none; */
}

.cancelIcon {
  position: absolute;
  right: 0;
  margin: 0px 15px 0px 0px;
}

@media (max-width: 768px) {
  /* .requestBtn */
}

.nftWrapper {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 8.95738px 27.9918px rgba(13, 10, 44, 0.06);
  border-radius: 8px;
  padding: 15px;
  height: 185px;
  width: 185px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.nftImage {
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  filter: drop-shadow(0px 8.95738px 27.9918px rgba(13, 10, 44, 0.06));
}

.shareLink {
  border-radius: 20px;
  font-family: Arial;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 16px;
  text-decoration: none;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  cursor: pointer;
  z-index: 1;
}

.swiperSlidesContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  align-content: center;
}
