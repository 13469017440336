/* Xtra - The Hero Section */
.hero__section {
  /* text-align: center;
  place-content: center;
  max-width: 100%; */
  margin-top: 180px;
  /* display: none; */
}

.hero__wrapper {
  /* padding: 10px; */
  /* display: none; */
}

.hero__header {
  /* font-size: 48px; */
  font-weight: 400;
  letter-spacing: 1.5;
  color: #aa0b22;
  font-family: "PT Sans", sans-serif;
  margin-top: 40px;
  /* display: none; */
}

.hero__paragraph {
  color: #eaeaea;
  width: 85%;
  /* display: none; */
  margin: 0px 0 50px 0;
}

.hero__search {
  width: 100%;
  position: relative;
}

.hero__search input {
  padding: 5px 1rem;
  width: 85%;
  border-radius: 8px;
  outline: none;
  border: none;
}

.hero__search input::placeholder {
  font-size: 12px;
  margin: 20px;
  padding-left: 10px;
}

.search {
  display: inline-block;
  background-color: #aa0b22;
  color: #fff;
  position: absolute;
  right: 5%;
  border-radius: 0 8px 8px 0;
  padding: 6px 20px;
  place-content: center;
  cursor: pointer;
  outline: none;
  border: 0;
}

.hero__others {
  margin-top: 4rem;
}

.process__container {
  width: 90%;
  margin: auto;
}

.content_wrap {
  display: flex;
  justify-content: space-between;
  margin-left: 4rem;
  margin-right: 4rem;
}

@media (max-width: 768px) {
  .hero__section {
    text-align: center;
    margin-top: 100px;
  }
  .hero__header {
    font-size: 30px;
    font-weight: 700;
  }

  .hero__wrapper {
    padding: 10px;
  }

  .hero__search {
    margin-bottom: 0;
  }

  .hero__search input {
    width: 70%;
    margin: auto;
    /* padding: 10px 3px; */
  }

  .hero__search input::placeholder {
    font-size: 10px;
    margin: 20px;
    padding-left: 10px;
  }
  .hero__paragraph {
    width: 60%;
    margin: 30px auto;
    /* display: none; */
    /* font-size: 10px; */
  }

  .hero__others {
    /* display: none; */
  }

  .content_wrap {
    margin: 0;
  }

  .search {
    /* display: none; */
    right: 7%;
  }

  .miles {
    z-index: -1;
  }

  .heroImage {
    /* padding: 20px; */
    width: 50%;
    margin-top: 50px;

    /* display: none; */
  }
}
